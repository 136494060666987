export const EVENTS = {
  FETCH: 'FETCH',
  RESOLVE: 'RESOLVE',
  REJECT: 'REJECT',
  CLEAR: 'CLEAR',
} as const;

export enum STATUSES {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export const PAGINATION = {
  DIRECTION: {
    BACK: 'back',
    NEXT: 'next',
  },
  PER_PAGE: [25, 50, 100],
};

export const TOGGLE_STATUSES = {
  ON: 'ON',
  OFF: 'OFF',
} as const;

export const USER_EMAIL = 'USER_EMAIL';
