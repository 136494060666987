import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import { COOKIES } from 'src/utils/constants';

import cookies from 'browser-cookies';

const Callback = () => {
  const authenticated = cookies.get(COOKIES.AUTHENTICATED);
  if (authenticated) {
    return <Redirect to={ROUTES.DEALERSHIPS} />;
  }

  return <Redirect to={ROUTES.LOGIN} />;
};

export default Callback;
