import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Callback from 'src/components/Callback';
import Contracts from 'src/pages/Contracts';
import Login from 'src/components/Login';
import Home from 'src/pages/Home';
import ManagedToggleGroups from 'src/pages/ManagedToggleGroups';
import CreateToggleGroup from 'src/pages/ManagedToggleGroups/CreateToggleGroup';
import EditToggleGroup from 'src/pages/ManagedToggleGroups/EditToggleGroup';
import SelectLocation from 'src/pages/SelectLocation';
import DynamicContent from 'src/pages/DynamicContent';
import ReindexVehicles from 'src/pages/ReindexVehicles';
import NotFound from 'src/components/NotFound';
import { ROUTES } from 'src/constants';
import PrivateRoute from 'src/components/PrivateRoute';

const Routes = () => (
  <Switch>
    <PrivateRoute exact path={ROUTES.HOME} component={Home} />
    <PrivateRoute exact path={ROUTES.MANAGED_TOGGLE_GROUPS.ROOT} component={ManagedToggleGroups} />
    <PrivateRoute exact path={ROUTES.MANAGED_TOGGLE_GROUPS.NEW} component={CreateToggleGroup} />
    <PrivateRoute exact path={ROUTES.MANAGED_TOGGLE_GROUPS.EDIT} component={EditToggleGroup} />
    <PrivateRoute path={ROUTES.CONTRACTS.ROOT} exact component={Contracts} />
    <PrivateRoute path={ROUTES.DEALERSHIPS} exact component={SelectLocation} />
    <PrivateRoute path={ROUTES.DYNAMIC_CONTENT} exact component={DynamicContent} />
    <PrivateRoute path={ROUTES.REINDEX_VEHICLES} exact component={ReindexVehicles} />
    <Route path={ROUTES.LOGIN} component={Login} />
    <Route path={ROUTES.CALLBACK} exact component={Callback} />
    {!window.location.href.includes('access_token') && (
      <Route path="*" exact component={NotFound} />
    )}
  </Switch>
);

export default Routes;
