import React from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { IMAGES, NavItem, NAV_ITEMS } from 'src/constants';
import AccountMenuContainer from 'src/components/AccountMenu';
import useJwtDecoder from 'src/utils/hooks/jwtDecoder';

const drawerWidth = 220;
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      bottom: {
        marginTop: 'auto',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      logo: {
        width: theme.spacing(19),
        padding: theme.spacing(3),
      },
      link: {
        '&:hover': {
          backgroundColor: '#F5F8FE',
        },
      },
    }),
);

const SideMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  useJwtDecoder();

  const renderedNavItems = () => (
    Object.values(NAV_ITEMS).map((navItem: NavItem) => {
      const {
        type,
        label,
        path,
      } = navItem;

      return (
        <Link
          key={type}
          style={{ textDecoration: 'none' }}
          to={path}
        >
          <ListItem
            button
            className={classes.link}
          >
            <ListItemText
              primary={t(label)}
              className="active"
            />
          </ListItem>
        </Link>
      );
    })
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
    >
      <img
        className={classes.logo}
        src={IMAGES.LOGO.url}
        alt={IMAGES.LOGO.alt}
      />
      <Divider />
      <List>
        {renderedNavItems()}
      </List>

      <div className={classes.bottom}>
        <AccountMenuContainer />
      </div>
    </Drawer>
  );
};

export default SideMenu;
