import React from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import {
  BOX_SHADOWS,
  drawerBreakpoint,
  drawerWidth,
  IMAGES,
} from 'src/constants';
import styles from 'src/styles/variables.scss';

interface Props {
  hasMenuIcon?: boolean;
  onMenuButtonClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: styles.white,
      boxShadow: BOX_SHADOWS.APP_BAR,
      marginLeft: drawerWidth,
    },
    condensedLogo: {
      height: 'auto',
      width: '130px',
    },
    menuButton: {
      color: styles.darkGray,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up(drawerBreakpoint)]: {
        display: 'none',
      },
    },
    menuIcon: {
      height: '30px',
      width: '30px',
    },
  }));

const Header = (props: Props) => {
  const classes = useStyles({});
  const {
    hasMenuIcon,
    onMenuButtonClick,
  } = props;

  const displayMenuIcon = hasMenuIcon && (
    <IconButton
      aria-label="open drawer"
      edge="start"
      onClick={onMenuButtonClick}
      className={classes.menuButton}
    >
      <MenuIcon className={classes.menuIcon} />
    </IconButton>
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        { displayMenuIcon }
        <img
          className={classes.condensedLogo}
          src={IMAGES.LOGO.url}
          alt={IMAGES.LOGO.alt}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
