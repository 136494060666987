export const validFeeInput = /^[0-9]{0,4}?(\.([0-9]{1,2})?)?$/;

export const isValidNumberInput = (str: string) => {
  if (str.length) {
    if (str.length === 1 && str[0] === '.') return true;
    return validFeeInput.test(str);
  }
  return true;
};

export const formatNumber = (value: number, precision: number = 2) => Number(value).toFixed(precision);
