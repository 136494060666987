import React, {
  ChangeEvent,
  FocusEvent,
  ReactNode,
} from 'react';
import {
  createStyles,
  InputAdornment,
  InputBaseComponentProps,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { INPUT_IDS } from 'src/constants';

const styles = require('src/styles/variables.scss');

interface Props {
  className?: string;
  defaultValue?: string;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  hasError?: boolean;
  helperText?: string;
  id: string;
  inputProps?: InputBaseComponentProps;
  isDisabled?: boolean;
  label: string;
  maxLength?: number;
  margin?: 'dense' | 'none';
  name?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onError?: (error: string) => void;
  prefix?: string;
  readOnly?: boolean;
  required?: boolean;
  suffix?: ReactNode | string;
  type?: string;
  value?: string;
  doPasswordsMatch?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorIcon: {
      color: styles.error50,
      height: '20px',
      width: '20px',
    },
    prefix: (props: Props) => ({
      color: props.isDisabled ? styles.darkGray : styles.gray800,
      fontSize: theme.spacing(2),
    }),
    suffix: (props: Props) => ({
      color: props.isDisabled ? styles.gray500 : styles.darkGray,
    }),
    suffixString: {
      fontSize: theme.spacing(2),
    },
  }));

const TextInput = (props: Props) => {
  const {
    className,
    defaultValue,
    endAdornment,
    fullWidth,
    hasError,
    helperText,
    id,
    inputProps,
    isDisabled,
    label,
    maxLength,
    margin,
    name,
    onBlur,
    onChange,
    onError,
    prefix,
    readOnly,
    required,
    suffix,
    type,
    value,
    doPasswordsMatch,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  const getEndAdornment = () => {
    if (endAdornment) return endAdornment;
    const className = typeof suffix === 'string'
      ? classes.suffixString
      : '';

    if (suffix) {
      return (
        <InputAdornment
          position="end"
          disableTypography
          className={`${classes.suffix} ${className}`}
        >
          {suffix}
        </InputAdornment>
      );
    }

    if (hasError) {
      return <ErrorOutline className={classes.errorIcon} />;
    }

    return null;
  };

  const getStartAdornment = () => Boolean(prefix) && (
    <InputAdornment
      position="start"
      disableTypography
      classes={{ root: classes.prefix }}
    >
      {prefix}
    </InputAdornment>
  );

  const handleError = (errorText: string) => {
    if (onError) {
      onError(errorText);
    }
  };

  // eslint-disable-next-line max-statements, complexity
  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    if (required && !value) {
      return handleError(t('errors.required'));
    }

    if (id === INPUT_IDS.CONFIRM_PASSWORD && !doPasswordsMatch) {
      return handleError(t('welcomePage.passwordMatchError'));
    }

    return handleError('');
  };

  return (
    <TextField
      autoComplete={id}
      className={className}
      defaultValue={defaultValue}
      disabled={isDisabled}
      error={hasError}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      InputProps={{
        endAdornment: getEndAdornment(),
        inputProps: { ...inputProps, maxLength },
        readOnly,
        startAdornment: getStartAdornment(),
      }}
      label={label}
      margin={margin}
      name={name}
      onBlur={handleOnBlur}
      onChange={onChange}
      required={required}
      type={type}
      value={value}
      variant="outlined"
    />
  );
};

export default TextInput;
