import React, { useEffect } from 'react';
import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ModalContainer from 'src/components/Modal/index';
import Routes from 'src/components/Routes';
import SideMenu from 'src/components/SideMenu/presenter';
import SnackbarContainer from 'src/components/Snackbar/index';
import { isUserAuthenticated } from 'src/store/actions/auth';
import { getDealership } from 'src/store/actions/currentDealership';
import { authUserState as authStateSelector } from 'src/store/selectors/auth';
import { TOGGLE_STATUSES } from 'src/store/lib/constants';
import { LOCAL_STORAGE } from 'src/constants';
import { getAuthentication } from 'src/utils/auth/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      display: 'flex',
    },
    container: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(5),
    },
    main: {
      flexGrow: 1,
    },
  }));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authenticated = useSelector(authStateSelector);

  useEffect(() => {
    const { authenticated, expiresAt } = getAuthentication();
    if (authenticated && Date.now() < expiresAt) {
      dispatch(isUserAuthenticated(TOGGLE_STATUSES.ON));
    } else {
      dispatch(isUserAuthenticated(TOGGLE_STATUSES.OFF));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!authenticated) return;

    const dealership = localStorage.getItem(LOCAL_STORAGE.CURRENT_DEALERSHIP);
    const data = JSON.parse(dealership);
    if (data?.id) {
      dispatch(getDealership(data.id));
    }
  }, [dispatch, authenticated]);

  return (
    <Box className={classes.app}>
      <SnackbarContainer />
      <ModalContainer />
      {authenticated && <SideMenu />}
      <Box role="main" className={classes.main}>
        <Container className={classes.container}>
          <Box>
            <Routes />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default App;
