import React, {
  BaseSyntheticEvent,
  Fragment,
  ReactNode,
  useState,
  MouseEvent,
} from 'react';
import {
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Checkbox,
  Box,
  LinearProgress,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import {
  IndeterminateCheckBox,
  ChevronRight,
  KeyboardArrowDown,
  MoreVert,
  ArrowDownward,
  ArrowUpward,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import {
  Column,
  RowObj,
  SelectedRows,
  RowAction,
  RowActionType,
  SortDirection,
} from 'src/components/Table/types';
import TableActionMenu from 'src/components/Table/TableActionMenu';

const styles = require('src/styles/variables.scss');

interface Props {
  actionable?: boolean;
  actions?: RowAction[];
  ariaLabel: string;
  columns: Column[];
  emptyTableMessage?: ReactNode;
  errorTableMessage?: ReactNode;
  expandable?: boolean;
  formatCell?: (row: RowObj, column: Column) => ReactNode;
  formatCellContent?: (row: RowObj, column: Column) => ReactNode;
  formatExpandedRow?: (row: RowObj) => ReactNode;
  formatHeaderContent?: (column: Column, className: string) => ReactNode;
  hasError?: boolean;
  HeaderComponent?: ReactNode;
  hideColHeaders?: boolean;
  isEveryRowSelected?: boolean;
  isLoading?: boolean;
  multiSelectEnabled?: boolean;
  onActionClick?: (type: RowActionType, row: RowObj) => void;
  onRowClick?: (row: RowObj) => void;
  onRowSelect?: (value: boolean, row: RowObj) => void;
  onSelectAll?: (value: boolean) => void;
  onSortClick?: (column: Column) => void;
  PaginationComponent?: ReactNode;
  rows: Array<RowObj>;
  selectedRows?: SelectedRows;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  cell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  },
  cellAction: {
    padding: theme.spacing(1),
    textAlign: 'center',
    borderLeft: `solid 1px ${styles.gray400}`,
  },
  cellFirst: {
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
  },
  cellLast: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
  },
  cellSpan: {
    fontWeight: styles.fontRegular,
  },
  checkbox: {
    padding: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  indeterminateCheck: {
    color: styles.primaryBlue,
  },
  colCheckboxHeader: {
    background: styles.white,
    lineHeight: 0,
    padding: theme.spacing(1.5),
  },
  colCheckboxIcon: {
    padding: 0,
  },
  colHeader: {
    color: (props: Props) => (props.isLoading ? styles.darkGray : styles.textGray),
    fontWeight: styles.fontBold,
  },
  actionableHeader: {
    borderLeft: `solid 1px ${styles.gray400}`,
    color: (props: Props) => (props.isLoading ? styles.darkGray : styles.textGray),
    fontWeight: styles.fontBold,
    textAlign: 'center',
  },
  sortableColLabel: {
    color: (props: Props) => (props.isLoading ? styles.darkGray : styles.textGray),
    cursor: 'pointer',
    fontWeight: styles.fontBold,
  },
  sortableColButton: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  sortArrow: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    marginLeft: theme.spacing(1),
  },
  sortArrowIcon: {
    width: '100%',
    height: '100%',
  },
  colHeaderCells: {
    backgroundColor: styles.white,
  },
  tableRow: {
    backgroundColor: styles.white,
    cursor: (props: Props) => (props.onRowClick ? 'pointer' : 'default'),
  },
  tableRowExpanded: {
    cursor: (props: Props) => (props.onRowClick ? 'pointer' : 'default'),
    backgroundColor: styles.blue50,
  },
  loader: {
    visibility: (props: Props) => (props.isLoading ? 'visible' : 'hidden'),
  },
}));

const Table = (props: Props) => { // eslint-disable-line max-statements
  const {
    actionable,
    actions,
    ariaLabel,
    columns,
    emptyTableMessage,
    errorTableMessage,
    expandable,
    formatCell,
    formatCellContent,
    formatExpandedRow,
    formatHeaderContent,
    hasError,
    HeaderComponent,
    hideColHeaders,
    isEveryRowSelected,
    isLoading,
    multiSelectEnabled,
    onActionClick,
    onRowClick,
    onRowSelect,
    onSelectAll,
    onSortClick,
    PaginationComponent,
    rows,
    selectedRows,
    title,
  } = props;
  const classes = useStyles(props);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [actionRow, setActionRow] = useState(null);
  const { t } = useTranslation();

  const handleExpansion = (row: RowObj) => {
    const updatedItems = new Set(expandedRows);
    if (updatedItems.has(row.id)) {
      updatedItems.delete(row.id);
    } else {
      updatedItems.add(row.id);
    }
    setExpandedRows(updatedItems);
  };

  const selectedRowCount = selectedRows
    ? Object.values(selectedRows).filter(value => !!value).length
    : 0;

  const handleSelectAll = (e: BaseSyntheticEvent) => {
    if (onSelectAll) onSelectAll(e.target.checked);
  };

  const handleActionClick = (event: MouseEvent<Element>, row: RowObj) => {
    setActionMenuAnchor(event.currentTarget);
    setActionRow(row);
  };

  const handleActionClose = () => {
    setActionMenuAnchor(null);
    setActionRow(null);
  };

  const handleActionItemClick = (actionType: RowActionType) => {
    onActionClick(actionType, actionRow);
    handleActionClose();
  };

  const getCellClass = (index: number, total: number) => {
    switch (index) {
      case 0:
        return classes.cellFirst;
      case total - 1:
        return classes.cellLast;
      default:
        return classes.cell;
    }
  };

  const handleSortClick = (header: Column) => (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (header.sortable && onSortClick) {
      onSortClick(header);
    }
  };

  const sortArrow = (sortDirection: SortDirection) => (
    sortDirection === SortDirection.DESC
      ? <ArrowDownward fontSize="small" className={classes.sortArrowIcon} />
      : <ArrowUpward fontSize="small" className={classes.sortArrowIcon} />
  );

  const headCellContent = (header: Column) => {
    if (formatHeaderContent) return formatHeaderContent(header, classes.colHeader);
    const { sortable, label, sortDirection } = header;
    const className = sortable ? classes.sortableColLabel : classes.colHeader;
    return (
      <div
        role="button"
        aria-pressed="false"
        aria-disabled={!sortable}
        tabIndex={0}
        className={classes.sortableColButton}
        onClick={handleSortClick(header)}
        onKeyDown={handleSortClick(header)}
      >
        <Typography
          variant="h6"
          component="span"
          className={className}
        >
          {t(label)}
        </Typography>
        <div className={classes.sortArrow}>
          {sortDirection && sortArrow(sortDirection)}
        </div>
      </div>
    );
  };

  const renderColHeaders = () => {
    const colHeaders = columns.map((header, index) => {
      const root = getCellClass(index, columns.length);
      return (
        <TableCell
          key={index}
          classes={{
            root,
            stickyHeader: classes.colHeaderCells,
          }}
        >
          { headCellContent(header) }
        </TableCell>
      );
    });

    if (multiSelectEnabled) {
      const actionDescription = isEveryRowSelected ? 'unselect' : 'select';
      colHeaders.unshift(
        <TableCell
          padding="checkbox"
          key="checkbox-header"
          classes={{ root: classes.colCheckboxHeader }}
        >
          <Checkbox
            disabled={!!isLoading}
            className={classes.colCheckboxIcon}
            color="primary"
            checked={!!isEveryRowSelected}
            onChange={handleSelectAll}
            indeterminate={!isEveryRowSelected && selectedRowCount > 0}
            indeterminateIcon={<IndeterminateCheckBox className={classes.indeterminateCheck} />}
            inputProps={{
              'aria-label': `${actionDescription} all rows`,
            }}
          />
        </TableCell>,
      );
    }

    if (expandable) {
      colHeaders.unshift(
        <TableCell
          key="expandable-header"
          padding="checkbox"
          classes={{ root: classes.colHeaderCells }}
        />,
      );
    }

    if (actionable) {
      colHeaders.push(
        <TableCell
          key="actionable-header"
          classes={{
            root: classes.colHeaderCells,
            stickyHeader: classes.colHeaderCells,
          }}
          className={classes.actionableHeader}
        >
          <Typography
            variant="h6"
            component="span"
            className={classes.colHeader}
          >
            {t('table.actionable.label')}
          </Typography>
        </TableCell>,
      );
    }

    return colHeaders;
  };

  const handleRowSelect = (row: any) => (e: BaseSyntheticEvent) => {
    if (onRowSelect) onRowSelect(e.target.checked, row);
  };

  const renderCells = (row: any, rowNumber: number | string) => {
    const cells = columns.map((column, index) => {
      if (formatCell) {
        return formatCell(row, column);
      }
      const value = row[column.id];
      const newValue = column.format ? column.format(value) : value;
      const root = getCellClass(index, columns.length);

      return (
        <TableCell
          key={index}
          classes={{ root }}
        >
          <Typography
            variant="h6"
            component="span"
            className={classes.cellSpan}
          >
            {formatCellContent
              ? formatCellContent(row, column)
              : newValue}
          </Typography>
        </TableCell>
      );
    });

    if (multiSelectEnabled) {
      const rowDescription = `${title} row ${rowNumber} selected`;
      cells.unshift(
        <TableCell
          padding="checkbox"
          key={`checkbox-${row.id}`}
          classes={{ root: classes.cell }}
        >
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={!!(selectedRows && selectedRows[row.id])}
            onChange={handleRowSelect(row)}
            inputProps={{ 'aria-label': rowDescription }}
          />
        </TableCell>,
      );
    }

    if (expandable) {
      const expanded = expandedRows.has(row.id);
      cells.unshift(
        <TableCell
          padding="checkbox"
          key={`expandable-${row.id}`}
          classes={{ root: classes.cell }}
        >
          <IconButton
            aria-label={t('table.expandable.button')}
            onClick={() => {
              handleExpansion(row);
            }}
          >
            {expanded
              ? <KeyboardArrowDown color="primary" /> : <ChevronRight color="primary" />}
          </IconButton>
        </TableCell>,
      );
    }

    if (actionable) {
      cells.push(
        <TableCell
          key={`actionable-${row.id}`}
          classes={{ root: classes.cellAction }}
        >
          <IconButton
            aria-label={t('table.actionable.button')}
            onClick={(e: MouseEvent) => { handleActionClick(e, row); }}
          >
            <MoreVert color="primary" />
          </IconButton>
        </TableCell>,
      );
    }
    return cells;
  };

  const renderRows = () => (
    rows && rows.map((row, index) => {
      const expanded = expandedRows.has(row.id);
      const primaryRowClass = expanded ? classes.tableRowExpanded : classes.tableRow;
      return (
        <Fragment key={index}>
          <TableRow
            className={primaryRowClass}
            onClick={onRowClick ? () => onRowClick(row) : null}
          >
            {renderCells(row, index)}
          </TableRow>
          {expanded
              && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={99}>
                    {formatExpandedRow(row)}
                  </TableCell>
                </TableRow>
              )}
        </Fragment>
      );
    })
  );

  const colHeader = (
    <TableHead>
      <TableRow>
        {renderColHeaders()}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer className={classes.container} component={HeaderComponent ? Paper : Box}>
      {HeaderComponent}
      <LinearProgress color="primary" className={classes.loader} />
      <Box className={classes.tableWrapper}>
        <MaterialTable stickyHeader aria-label={ariaLabel}>
          {!hideColHeaders && colHeader}
          <TableBody>
            {!hasError && renderRows()}
          </TableBody>
        </MaterialTable>
        {hasError && errorTableMessage}
        {rows && !rows.length && !hasError && !isLoading && emptyTableMessage}
      </Box>
      {PaginationComponent}
      <TableActionMenu
        anchorEl={actionMenuAnchor}
        handleClose={handleActionClose}
      >
        {actions?.map((a: RowAction) => (
          <MenuItem
            id={a.id}
            key={a.label}
            onClick={() => handleActionItemClick(a.type)}
          >
            {t(a.label)}
          </MenuItem>
        ))}
      </TableActionMenu>
    </TableContainer>
  );
};

export default Table;
