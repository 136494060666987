import { push } from 'connected-react-router';

import eventCreator from 'src/store/lib/eventCreator';
import { GET_DEALERSHIP } from 'src/store/actions/currentDealership/constants';
import { openSnackbar } from 'src/store/actions/snackbar';
import { ThunkDispatch } from 'src/store/types';
import {
  LOCAL_STORAGE,
  ROUTES,
  SNACKBAR_TYPES,
} from 'src/constants';
import { currentDealership } from 'src/store/lib/endpoints';
import Api from 'src/utils/api';
import i18n from 'src/translations/i18n';
import { AppState } from 'src/store/reducers';

const getDealershipEvent = eventCreator(GET_DEALERSHIP);

export const clearCurrentDealership = () =>
  (dispatch: ThunkDispatch) => {
    dispatch(getDealershipEvent.clear());
    localStorage.removeItem(LOCAL_STORAGE.CURRENT_DEALERSHIP);
  };

export const getDealership = (dealershipId: number, errorMessage: string = i18n.t('errors.generic')) =>
  (dispatch: ThunkDispatch, getState: () => AppState) => {
    dispatch(getDealershipEvent.fetch());
    const { pathname } = getState().router.location;

    return Api.get(currentDealership(dealershipId))
      .then(res => {
        const data = res;
        dispatch(getDealershipEvent.resolve(data));
        localStorage.setItem(LOCAL_STORAGE.CURRENT_DEALERSHIP, JSON.stringify(data));
        if ([ROUTES.LOGIN, ROUTES.DEALERSHIPS].includes(pathname)) {
          dispatch(push(ROUTES.HOME));
        }
      })
      .catch(error => {
        dispatch(getDealershipEvent.reject(error.message));
        dispatch(openSnackbar(
          SNACKBAR_TYPES.ERROR,
          errorMessage,
        ));
        dispatch(clearCurrentDealership());
        return error;
      });
  };
