import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { snackbar as snackbarSelector } from 'src/store/selectors/snackbar';
import { closeSnackbar } from 'src/store/actions/snackbar';
import Snackbar from 'src/components/Snackbar/presenter';

const SnackbarContainer = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(snackbarSelector);

  const handleClose = useCallback(
    () => {
      dispatch(closeSnackbar());
    },
    [dispatch],
  );

  return (
    <Snackbar
      closeSnackbar={handleClose}
      snackbar={snackbar}
    />
  );
};

export default SnackbarContainer;
