import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  DeleteOutline,
  DragIndicator,
  EditOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { AgreementToggle, Translations } from 'src/utils/hooks/agreementToggles/types';
import SecondaryText from 'src/components/Text/SecondayText';
import styles from 'src/styles/variables.scss';

interface Props {
  data: AgreementToggle;
  index: number;
  onDelete?: (id: number) => void;
  isCreating?: boolean;
  canAddMultipleTranslations?: boolean;
  onEnableEdit?: (id: number, text: string, translations: Translations[]) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  content: {
    marginBottom: ({ canAddMultipleTranslations }: Props) => (canAddMultipleTranslations
      ? theme.spacing(4) : theme.spacing(1)),
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: styles.error50,
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  dragIcon: {
    flex: 0,
    color: styles.gray700,
    marginRight: theme.spacing(2),
  },
  editIcon: {
    color: styles.primaryBlue,
  },
  iconDisabled: {
    color: styles.gray500,
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  spacer: {
    marginBottom: theme.spacing(1),
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    color: styles.gray700,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
  },
  readOnly: {
    color: styles.darkGray,
    paddingLeft: theme.spacing(5),
  },
  fieldsContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  buttonsContainer: {
    flexShrink: 0,
  },
}));

const AgreementItem = (props: Props) => {
  const {
    data: {
      created_at: createdAt,
      id,
      read_only: readOnly,
      text,
      translations,
      updated_at: updatedAt,
    },
    isCreating,
    canAddMultipleTranslations,
    onDelete,
    onEnableEdit,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  const canShowFrenchText = (translation: Translations) => {
    if (translation.text.length > 0) {
      return (
        <Fragment key={translation.language}>
          <Typography className={classes.spacer} variant="h6">
            French
          </Typography>
          <Typography variant="body1" data-testid="text">
            {translation.text}
          </Typography>
          <Box className={classes.spacer} />
          <SecondaryText
            createdAt={createdAt}
            readOnly={false}
            updatedAt={updatedAt}
          />
        </Fragment>
      );
    }

    return null;
  };

  return (
    <Box className={classes.container}>
      <div><DragIndicator className={classes.dragIcon} /></div>
      <Box className={classes.fieldsContainer}>
        <div className={classes.content}>
          <Typography className={classes.spacer} variant="h6">
            English
          </Typography>
          <Typography variant="body1" data-testid="text">
            {text}
          </Typography>
          <Box className={classes.spacer} />
          <SecondaryText
            createdAt={createdAt}
            readOnly={readOnly && !canAddMultipleTranslations}
            updatedAt={updatedAt}
          />
        </div>
        <div className={classes.content}>
          {translations.map(translation => canShowFrenchText(translation))}
        </div>
      </Box>
      <div className={classes.buttonsContainer}>
        <IconButton
          data-analytics-domain="settings-agreement-summary-edit-button"
          aria-label={t('agreementSummary.ariaLabels.edit')}
          className={classes.iconButton}
          disabled={readOnly || isCreating}
          onClick={() => onEnableEdit(id, text, translations)}
        >
          <EditOutlined
            className={readOnly || isCreating ? classes.iconDisabled : classes.editIcon}
          />
        </IconButton>
        <IconButton
          data-analytics-domain="settings-agreement-summary-delete-button"
          aria-label={t('agreementSummary.ariaLabels.delete')}
          className={classes.iconButton}
          disabled={readOnly}
          onClick={() => onDelete(id)}
        >
          <DeleteOutline
            className={readOnly ? classes.iconDisabled : classes.deleteIcon}
          />
        </IconButton>
      </div>
    </Box>
  );
};

export default AgreementItem;
