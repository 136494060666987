import React from 'react';
import { PageTitle } from 'dw-react-recipes';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';

const FallbackComponent = () => {
  const { t } = useTranslation();

  const goHome = () => {
    window.location.href = '/';
  };

  return (
    <>
      <PageTitle
        title={t('errors.generic')}
      />
      <Button
        variant="contained"
        label={t('errors.goBack')}
        onClick={goHome}
      />
    </>

  );
};

export default FallbackComponent;
