import React from 'react';
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { BOX_SHADOWS } from 'src/constants';

const styles = require('src/styles/variables.scss');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: styles.lightGray,
    },
    paper: {
      boxShadow: BOX_SHADOWS.SKELETON,
    },
    skeleton: {
      backgroundColor: styles.lightGray,
    },
    wrapperContent: {
      padding: theme.spacing(4),
    },
    wrapperHeader: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }));

const SkeletonLoader = () => {
  const classes = useStyles({});

  return (
    <Paper className={classes.paper}>
      <Box className={classes.wrapperHeader}>
        <Skeleton
          variant="rect"
          height="26px"
          width="33%"
          classes={{
            root: classes.skeleton,
          }}
        />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.wrapperContent}>
        <Skeleton
          variant="rect"
          height="145px"
          width="100%"
          classes={{
            root: classes.skeleton,
          }}
        />
      </Box>
    </Paper>
  );
};

export default SkeletonLoader;
