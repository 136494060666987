import { GET_ALL_FORMS } from 'src/store/actions/forms/constants';
import { Form, FetchFormState } from 'src/store/reducers/forms/types';
import { EVENTS, STATUSES } from 'src/store/lib/constants';

type FormsEvent = {
  data: Form[],
  error: string,
  type: string,
};

export const initialFetchFormState: FetchFormState = {
  status: STATUSES.IDLE,
  data: null,
  error: null,
};

const idleReducer = (
  state: FetchFormState = initialFetchFormState,
  event: FormsEvent,
): FetchFormState => {
  switch (event.type) {
    case `${GET_ALL_FORMS}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    default:
      return state;
  }
};

const loadingReducer = (
  state: FetchFormState = initialFetchFormState,
  event: FormsEvent,
): FetchFormState => {
  switch (event.type) {
    case `${GET_ALL_FORMS}/${EVENTS.RESOLVE}`:
      return {
        ...state,
        status: STATUSES.SUCCESS,
        data: event.data,
        error: null,
      };
    case `${GET_ALL_FORMS}/${EVENTS.REJECT}`:
      return {
        ...state,
        data: null,
        status: STATUSES.FAILURE,
        error: event.error,
      };
    default:
      return state;
  }
};

const successFailureReducer = (
  state: FetchFormState = initialFetchFormState,
  event: FormsEvent,
): FetchFormState => {
  switch (event.type) {
    case `${GET_ALL_FORMS}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    case `${GET_ALL_FORMS}/${EVENTS.CLEAR}`:
      return initialFetchFormState;
    default:
      return state;
  }
};

const fetch = (
  state: FetchFormState = initialFetchFormState,
  event: FormsEvent,
): FetchFormState => {
  switch (state.status) {
    case STATUSES.IDLE:
      return idleReducer(state, event);
    case STATUSES.LOADING:
      return loadingReducer(state, event);
    case STATUSES.SUCCESS:
    case STATUSES.FAILURE:
      return successFailureReducer(state, event);
    default:
      return state;
  }
};

export default fetch;
