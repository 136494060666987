import axios from 'axios';
import { baseUrl, getHeaders } from 'src/utils/api/config';
import { HttpHeaders } from 'src/utils/api/types';
import { handleError } from './error';

const Api = {
  get: (endpoint: string, params?: Object) =>
    axios(`${baseUrl}${endpoint}`, {
      method: 'GET',
      headers: getHeaders(),
      params,
    })
      .then(res => res.data)
      .catch(err => {
        throw handleError(err);
      }),
  put: (endpoint: string, payload: any) =>
    axios(`${baseUrl}${endpoint}`, {
      method: 'PUT',
      headers: getHeaders(),
      data: JSON.stringify(payload),
    })
      .then(res => res)
      .catch(err => {
        throw handleError(err);
      }),
  patch: (endpoint: string, payload: any) =>
    axios(`${baseUrl}${endpoint}`, {
      method: 'PATCH',
      headers: getHeaders(),
      data: JSON.stringify(payload),
    })
      .then(res => res)
      .catch(err => {
        throw handleError(err);
      }),
  post: (endpoint: string, payload: any) =>
    axios(`${baseUrl}${endpoint}`, {
      method: 'POST',
      headers: getHeaders(),
      data: JSON.stringify(payload),
    })
      .then(res => res)
      .catch(err => {
        throw handleError(err, true);
      }),
  postFormData: (endpoint: string, payload: any, headers?: HttpHeaders<string>) =>
    axios(`${baseUrl}${endpoint}`,
      {
        method: 'POST',
        headers: headers || getHeaders(),
        data: payload,
      })
      .then(res => res)
      .catch(err => {
        throw handleError(err, true);
      }),
  delete: (endpoint: string, payload?: any) =>
    axios(`${baseUrl}${endpoint}`, {
      method: 'DELETE',
      headers: getHeaders(),
      data: payload,
    })
      .then(res => res)
      .catch(err => {
        throw handleError(err);
      }),
};

export default Api;
