import React, { KeyboardEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

import Button from 'src/components/Button';
import { ModalData } from 'src/store/types';
import { BOX_SHADOWS } from 'src/constants';

const styles = require('src/styles/variables.scss');

interface Props {
  data: ModalData;
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  actions: {
    padding: 0,
  },
  body: {
    color: styles.textGray,
    marginBottom: theme.spacing(5),
  },
  confirmButton: {
    marginLeft: theme.spacing(1.5),
  },
  content: {
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(3)}px}`,
  },
  paper: {
    boxShadow: BOX_SHADOWS.PAPER,
  },
  title: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px}`,
  },
}));

const ConfirmModal = (props: Props) => {
  const {
    data,
    isOpen,
    onCancel,
    onClose,
    onConfirm,
  } = props;
  const {
    title,
    body,
    cancelText,
    confirmText,
  } = data;
  const classes = useStyles({});

  const handleKeyPress = ({ key }: KeyboardEvent) => key === 'Enter' && onConfirm();

  return (
    <Dialog
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      disableBackdropClick
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
      onKeyPress={handleKeyPress}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        disableTypography
        className={classes.title}
      >
        <Typography
          variant="h2"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText
          id="confirm-dialog-description"
          className={classes.body}
        >
          {body}
        </DialogContentText>
        <DialogActions className={classes.actions}>
          <Button
            variant="text"
            label={cancelText}
            onClick={onCancel}
          />
          <Button
            variant="contained"
            label={confirmText}
            onClick={onConfirm}
            className={classes.confirmButton}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
