import { combineReducers } from 'redux';
import { FormsState } from 'src/store/reducers/forms/types';
import fetch, { initialFetchFormState } from 'src/store/reducers/forms/fetch';
import upload, { initialUploadFormState } from 'src/store/reducers/forms/upload';

export const initialFormsState: FormsState = {
  fetch: initialFetchFormState,
  upload: initialUploadFormState,
};

export default combineReducers({
  fetch,
  upload,
});
