import * as cookies from 'browser-cookies';

import { HttpHeaders } from 'src/utils/api/types';
import { COOKIES } from 'src/utils/constants';

export const getHeaders = (): HttpHeaders<string> => ({
  Accept: 'application/json',
  Authorization: `Bearer ${cookies.get(COOKIES.ID_TOKEN)}`,
  'Content-Type': 'application/json',
});

export const getPagination = (headers: { [key: string]: string }) => ({
  page: parseInt(headers.page, 10),
  perPage: parseInt(headers['per-page'], 10),
  total: parseInt(headers.total, 10),
});

export const baseUrl = `${process.env.API_HOST}:${process.env.API_PORT}`;
