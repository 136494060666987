import {
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR,
} from 'src/store/actions/snackbar/constants';
import { SnackbarState, SnackbarAction } from 'src/store/reducers/snackbar/types';

export const initialSnackbarState: SnackbarState = {
  isOpen: false,
  type: '',
  message: '',
};

const snackbar = (state = initialSnackbarState, action: SnackbarAction) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        isOpen: true,
        message: payload.message,
        type: payload.type,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        isOpen: false,
        type: '',
        message: '',
      };
    default:
      return state;
  }
};

export default snackbar;
