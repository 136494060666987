import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CurrentDealershipData } from 'src/store/reducers/currentDealership/types';
import { ACCOUNT_MENU, ACCOUNT_MENU_TYPES } from 'src/constants';
import { truncateText } from 'src/utils/formatters';

const styles = require('src/styles/variables.scss');

const NAME_CHAR_LIMIT = 20;
const DEALER_CHAR_LIMIT = 22;

interface Props {
  currentDealership: CurrentDealershipData;
  handleClick: (type: ACCOUNT_MENU_TYPES) => void;
  userEmail: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    primaryText: {
      fontSize: '14px',
      fontWeight: styles.fontSemibold,
    },
    secondaryText: {
      fontSize: '14px',
    },
    subNav: {
      paddingLeft: '16px',
      '&:hover': {
        backgroundColor: styles.white,
      },
    },
  }));

const AccountMenu = (props: Props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    currentDealership,
    handleClick,
    userEmail,
  } = props;

  const dealership = currentDealership?.name || t('navItems.noDealerships');

  const PrimaryText = (
    <Typography title={userEmail} className={classes.primaryText}>
      {truncateText(userEmail, NAME_CHAR_LIMIT)}
    </Typography>
  );

  const SecondaryText = (
    <Typography title={dealership} className={classes.secondaryText}>
      {truncateText(dealership, DEALER_CHAR_LIMIT)}
    </Typography>
  );

  const MenuItems = (
    <>
      {Object.values(ACCOUNT_MENU.menuItems).map(({
        label, type, path, id,
      }) => {
        const Content = (
          <ListItem
            key={type}
            button
            className={classes.subNav}
            onClick={() => handleClick(type)}
          >
            <ListItemText
              disableTypography
              primary={t(label)}
            />
          </ListItem>
        );

        return (
          type === ACCOUNT_MENU_TYPES.LOG_OUT
            ? Content
            : (
              <Link to={path} id={id} style={{ textDecoration: 'none' }} key={type}>
                {Content}
              </Link>
            )
        );
      })}
    </>
  );

  return (
    <>
      <ListItem
        button
        id="account-menu-item-root"
      >
        <ListItemText
          primary={PrimaryText}
          secondary={SecondaryText}
        />
      </ListItem>
      <List component="div" disablePadding>
        {MenuItems}
      </List>
    </>
  );
};

export default AccountMenu;
