import React, { MouseEvent, ReactNode } from 'react';
import {
  Menu,
} from '@material-ui/core';

interface Props {
  anchorEl: Element;
  handleClose: (e: MouseEvent) => void;
  children?: ReactNode;
}

export default function TableActionMenu(props: Props) {
  const { anchorEl, handleClose, children } = props;
  const open = Boolean(anchorEl);

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
}
