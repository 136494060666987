import { USER_EMAIL } from 'src/store/lib/constants';
import { UserState } from 'src/store/reducers/user/types';

type UserEvent = {
  type: string;
  data: string;
};

export const initialUserState: UserState = {
  email: '',
};

export const userReducer = (
  state: UserState = initialUserState,
  event: UserEvent,
): UserState => {
  const { type, data } = event;
  switch (type) {
    case USER_EMAIL:
      return {
        ...state,
        email: data,
      };
    default:
      return state;
  }
};
