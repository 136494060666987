import React, { useRef, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from 'dw-react-recipes';
import { useTranslation } from 'react-i18next';

const styles = require('src/styles/variables.scss');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(3),
      border: `2px solid ${styles.gray400}`,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    headerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 0,
    },
    iconColumn: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      spaceBetween: theme.spacing(2.5),
    },
    button: {
      marginRight: theme.spacing(2.5),
      padding: theme.spacing(1),
    },
  }));

interface Props {
  name: string;
  deletable: boolean;
  editable: boolean;
  onDeleteClick?: () => void;
  onEditClick: () => void;
}

const GroupCard = (props: Props) => {
  const {
    name, editable, deletable, onDeleteClick, onEditClick,
  } = props;
  const classes = useStyles({});
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const typographyRef = useRef<HTMLHeadingElement | null>(null);

  const handleMouseEnter = () => {
    const { clientWidth, scrollWidth } = typographyRef.current;
    setIsOpen(clientWidth >= scrollWidth);
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <Box className={classes.headerRow}>
        <Tooltip title={name} onMouseEnter={handleMouseEnter} disableHoverListener={isOpen}>
          <Typography ref={typographyRef} noWrap variant="subtitle1">{name}</Typography>
        </Tooltip>
        <Box className={classes.iconColumn}>
          <IconButton
            className={classes.button}
            disabled={!editable}
            icon={<EditIcon />}
            ariaLabel={t('groupCard.buttons.edit.aria')}
            onClick={onEditClick}
          />
          <IconButton
            disabled={!deletable}
            icon={<DeleteIcon />}
            ariaLabel={t('groupCard.buttons.delete.aria')}
            onClick={onDeleteClick}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default GroupCard;
