import { MouseEvent, SyntheticEvent } from 'react';

import {
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR,
} from 'src/store/actions/snackbar/constants';
import { ThunkDispatch } from 'src/store/types';
import { SNACKBAR_TYPES, CLICK_AWAY } from 'src/constants';

export const openSnackbar = (type: SNACKBAR_TYPES, message: string) =>
  (dispatch: ThunkDispatch) => {
    dispatch({
      type: OPEN_SNACKBAR,
      payload: {
        type,
        message,
      },
    });
  };

export const closeSnackbar = (
  event?: SyntheticEvent | MouseEvent,
  reason?: string,
) => (dispatch: ThunkDispatch) => {
  if (reason === CLICK_AWAY) {
    return;
  }

  dispatch({
    type: CLOSE_SNACKBAR,
  });
};
