import React, { ReactNode } from 'react';
import {
  Grid,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { PollOutlined } from '@material-ui/icons';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';

const styles = require('src/styles/variables.scss');

interface Props {
  accentColor?: string;
  icon?: ReactNode;
  textLines: ReactNode[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  data: {
    flex: 1,
    padding: theme.spacing(2),
  },
  grid: {
    height: '100%',
    width: '100%',
  },
  icon: {
    color: styles.gray700,
    height: '100%',
    padding: theme.spacing(0.5),
    width: '100%',
  },
  iconContainer: {
    width: theme.spacing(6),
  },
  paper: {
    padding: 0,
    width: '100%',
  },
  sidebar: {
    backgroundColor: (props: Props) => props.accentColor || styles.gray700,
    borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    width: theme.spacing(0.5),
  },
  textbox: {
    flex: 1,
    height: '100%',
    paddingLeft: theme.spacing(2),
  },
}));

const InfoCard = (props: Props) => {
  const { icon, textLines } = props;
  const classes = useStyles(props);

  const infoLines = textLines.map((text, idx) => (
    <Typography
      key={`infoLine${idx}`}
      variant="body2"
    >
      { text }
    </Typography>
  ));

  return (
    <Paper
      className={classes.paper}
      elevation={1}
    >
      <Grid
        className={classes.grid}
        container
        direction="row"
        justifyContent="flex-start"
      >
        <Grid
          className={classes.sidebar}
          item
        />
        <Grid
          alignItems="center"
          className={classes.data}
          container
          direction="row"
          item
          justifyContent="flex-start"
        >
          <Grid
            alignItems="stretch"
            className={classes.iconContainer}
            container
            item
            justifyContent="center"
          >
            {icon || <PollOutlined className={classes.icon} />}
          </Grid>
          <Grid
            alignItems="flex-start"
            className={classes.textbox}
            container
            direction="column"
            item
            justifyContent="center"
          >
            <Grid item>
              { infoLines }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InfoCard;
