import { EVENTS } from 'src/store/lib/constants';

export type Pagination = {
  page: number,
  perPage: number,
  total: number,
};

interface Event<T = any> {
  type: string;
  data?: T;
  pagination?: Pagination;
  error?: string | null;
}

interface EventCreator {
  fetch: (isQuiet?: boolean) => Event;
  resolve: (data?: any, pagination?: Pagination) => Event;
  reject: (error?: string) => Event;
  clear: () => Event;
}

const eventCreator = (constant: string): EventCreator => ({
  fetch: (isQuiet?: boolean) => ({
    type: `${constant}/${EVENTS.FETCH}`,
    isQuiet,
  }),
  resolve: (data: any, pagination?: Pagination) => ({
    type: `${constant}/${EVENTS.RESOLVE}`,
    data,
    pagination,
  }),
  reject: (error?: string) => ({
    type: `${constant}/${EVENTS.REJECT}`,
    error: error || 'Something went wrong',
  }),
  clear: () => ({ type: `${constant}/${EVENTS.CLEAR}` }),
});

export default eventCreator;
