import useSWR from 'swr';

import fetcher from 'src/utils/api/fetcher';
import { managedToggleGroups, managedToggleGroup } from 'src/store/lib/endpoints';
import { PayloadGroup, PayloadGroups } from 'src/utils/hooks/manageToggleGroups/types';

export const useManageToggleGroups = (): PayloadGroups => {
  const { data: response, error, mutate } = useSWR(managedToggleGroups, fetcher);
  const data = response ? response.data : null;
  const pagination = response ? response.pagination : null;

  return {
    data,
    hasError: Boolean(error),
    isLoading: !error && !data,
    mutate,
    pagination,
  };
};

export const useManageToggleGroup = (id: number): PayloadGroup => {
  const { data: response, error, mutate } = useSWR(managedToggleGroup(id), fetcher);
  const data = response ? response.data : null;

  return {
    data,
    hasError: Boolean(error),
    isLoading: !error && !data,
    mutate,
  };
};
