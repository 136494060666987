import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from 'src/store/actions/modal/constants';
import { ThunkDispatch } from 'src/store/types';

export const openModal = (modalType: string, data: any) =>
  (dispatch: ThunkDispatch) => {
    dispatch({
      type: OPEN_MODAL,
      payload: { modalType, data },
    });
  };

export const closeModal = () =>
  (dispatch: ThunkDispatch) => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };
