/* eslint-disable import/no-mutable-exports */
import React, { ComponentType, Fragment } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { APP_VERSION } from 'src/constants';

const RELEASE_STAGES = ['tst', 'feature', 'stg', 'prd'];

interface Props {
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
  }>;
}

let ErrorBoundary: ComponentType<Props> = Fragment;
let notifyBugsnag: (error: Error) => void = null;

try {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: RELEASE_STAGES,
    releaseStage: process.env.APP_ENV,
    appVersion: APP_VERSION,
  });

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  notifyBugsnag = (error: Error) => {
    Bugsnag.notify(error);
  };
} catch (error) {
  // eslint-disable-next-line
  console.error('Cannot load Bugsnag:', error.message);
}

export { ErrorBoundary, notifyBugsnag };
