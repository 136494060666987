import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'dw-react-recipes';

import ButtonContainer from 'src/components/Button';
import { authenticateUser } from 'src/utils/auth/auth';
import { IMAGES } from 'src/constants';

import { isUserAuthenticated } from 'src/store/actions/auth';
import { TOGGLE_STATUSES } from 'src/store/lib/constants';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dwLogo: {
    height: 'auto',
    width: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  outerContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: 0,
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    borderRadius: '4px 0 0 4px',
    display: 'none',
    width: theme.spacing(38),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  image: {
    height: '100%',
  },
  loginContainer: {
    backgroundColor: 'white',
    borderRadius: '0 4px 4px 0',
    boxShadow: '-40px 0 30px -30px rgba(16, 42, 62, 0.08), -15px 0 10px -10px rgba(23, 33, 41, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(45),
    },
  },
  loginWrapper: {
    alignItems: 'center',
    marginTop: theme.spacing(12),
  },
}));

const Login = () => {
  const { t } = useTranslation();
  const onClick = () => authenticateUser();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isUserAuthenticated(TOGGLE_STATUSES.OFF));
  }, [dispatch]);

  return (
    <section className={classes.outerContainer}>
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          alt={IMAGES.LOGIN.alt}
          src={IMAGES.LOGIN.url}
        />
      </Box>
      <Box className={classes.loginContainer}>
        <img
          className={classes.dwLogo}
          alt={IMAGES.SM_LOGO.alt}
          src={IMAGES.SM_LOGO.url}
        />
        <Box className={classes.loginWrapper}>
          <PageTitle
            title={t('loginPage.title')}
          />
          <ButtonContainer
            variant="contained"
            onClick={onClick}
            label={t('loginPage.label')}
          />
        </Box>
      </Box>
    </section>
  );
};

export default Login;
