import React, {
  Fragment,
  useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import Button from 'src/components/Button';
import AgreementList from 'src/components/AgreementToggle/AgreementList';
import AgreementForm from 'src/components/AgreementToggle/AgreementForm';
import styles from 'src/styles/variables.scss';

interface Props {
  toggleGroupId: number,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    button: {
      textTransform: 'uppercase',
      marginLeft: theme.spacing(2),
    },
    addIcon: {
      color: styles.primaryBlue,
    },
  }));

const AgreementSummary = (props: Props) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const { toggleGroupId } = props;

  const handleClick = () => {
    setShowForm(true);
  };

  const handleCancelAgreement = () => {
    setShowForm(false);
  };

  const canShowAgreementForm = () => {
    if (showForm) {
      return <AgreementForm toggleGroupId={toggleGroupId} onCancel={handleCancelAgreement} />;
    }

    return (
      <Box className={classes.card}>
        <Button
          className={classes.button}
          label="ADD MANAGE TOGGLE"
          onClick={handleClick}
          startIcon={<Add className={classes.addIcon} />}
          variant="outlined"
        />
      </Box>
    );
  };

  return (
    <>
      <AgreementList
        toggleGroupId={toggleGroupId}
      />
      {canShowAgreementForm()}
    </>
  );
};

export default AgreementSummary;
