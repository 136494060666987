import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import Api from 'src/utils/api';
import {
  AgreementLanguages,
  AGREEMENT_AVAILABLE_LANGUAGES,
  MAX_AGREEMENT_LENGTH,
  SNACKBAR_TYPES,
} from 'src/constants';
import { openSnackbar } from 'src/store/actions/snackbar';

import Button from 'src/components/Button';
import { SupportedLanguage } from 'src/utils/hooks/agreementToggles/types';
import { agreementToggles } from 'src/store/lib/endpoints';
import { useAgreementsToggle } from 'src/utils/hooks/agreementToggles';
import styles from 'src/styles/variables.scss';

interface Props {
  isEditing?: boolean;
  isFormValid?: boolean;
  toggleGroupId: number;
  canAddMultipleTranslations?: boolean;
  onCancel: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: FormEvent) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonContainer: {
    alignSelf: ({ canAddMultipleTranslations }: Props) => (canAddMultipleTranslations ? 'flex-end' : 'center'),
    marginTop: ({ canAddMultipleTranslations }: Props) => (
      canAddMultipleTranslations ? theme.spacing(0) : theme.spacing(3)
    ),
    marginBottom: ({ canAddMultipleTranslations }: Props) => (
      canAddMultipleTranslations ? theme.spacing(4) : theme.spacing(0)
    ),
    marginLeft: theme.spacing(1),
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  dragIcon: {
    alignSelf: 'flex-start',
    color: styles.gray700,
    marginRight: theme.spacing(2),
  },
  input: {
    '&::placeholder': {
      color: styles.textGray,
    },
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  textField: {
    backgroundColor: styles.backgroundGray,
    width: '100%',
  },
  field: {
    marginBottom: ({ canAddMultipleTranslations }: Props) =>
      (canAddMultipleTranslations ? theme.spacing(4) : theme.spacing(0)),
  },
  spacer: {
    marginBottom: theme.spacing(1),
  },
}));

const supportedLanguages = Object.keys(AGREEMENT_AVAILABLE_LANGUAGES);

const initialAgreementTranslations = supportedLanguages.reduce((acc, value) => {
  acc[value] = '';
  return acc;
}, {} as AgreementLanguages);

const AgreementForm = (props: Props) => {
  const {
    isEditing,
    toggleGroupId,
    onCancel,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    agreementTranslations,
    setAgreementTranslations,
  ] = useState<AgreementLanguages>(initialAgreementTranslations);
  const [isFormValid, setIsFormValid] = useState(false);

  const {
    mutate,
  } = useAgreementsToggle(toggleGroupId);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAgreementTranslations({ ...agreementTranslations, [name]: value });
    if (supportedLanguages.some(key => agreementTranslations[key].length > 0)) {
      setIsFormValid(true);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const text = agreementTranslations.en.trim();
    const textFr = agreementTranslations.fr.trim();
    const url = agreementToggles(toggleGroupId);

    try {
      await Api.post(url, {
        text: text || textFr,
        translations: JSON.stringify([{ language: 'fr', text: textFr }]),
      });
      setAgreementTranslations(initialAgreementTranslations);
    } catch (error) {
      dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
    } finally {
      mutate();
    }
  };

  const formTitles = (lng: string) => (lng === 'en' ? 'English' : 'French (Optional)');

  const displayPlaceholder = (lng: string) => {
    if (lng === 'fr') {
      return 'Add a French translation of the Agreement Statement here.';
    }
    return 'Enter an agreement statement here.';
  };

  return (
    <>
      <form className={classes.container} onSubmit={handleSubmit}>
        <DragIndicator className={classes.dragIcon} />
        <Box className={classes.fieldsContainer}>
          {supportedLanguages.map((language: SupportedLanguage) => (
            <Box className={classes.field} key={language}>
              <Typography className={classes.spacer} variant="h6">
                {formTitles(language)}
              </Typography>
              <TextField
                className={classes.textField}
                name={language}
                inputProps={{
                  classes: { input: classes.input },
                  maxLength: MAX_AGREEMENT_LENGTH,
                }}
                autoFocus={language === 'en'}
                multiline
                onChange={handleChange}
                placeholder={displayPlaceholder(language)}
                minRows={4}
                maxRows={6}
                value={agreementTranslations[language]}
                variant="outlined"
              />
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.cancel}
            label="CANCEL"
            onClick={onCancel}
            size="small"
            variant="text"
          />
          <Button
            isDisabled={!isFormValid}
            label="SAVE"
            size="small"
            type="submit"
            variant="contained"
          />
        </Box>
      </form>
      {isEditing && <Divider className={classes.divider} />}
    </>
  );
};

export default AgreementForm;
