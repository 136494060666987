import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import DateTime from 'src/utils/time/dateTime';

const styles = require('src/styles/variables.scss');

interface Props {
  createdAt: string;
  readOnly: boolean;
  updatedAt: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
  },
  readOnly: {
    color: styles.darkGray,
    marginLeft: theme.spacing(4),
  },
  spacer: {
    marginRight: theme.spacing(4),
  },
  text: {
    color: styles.darkGray,
  },
}));

const SecondaryText = (props: Props) => {
  const { createdAt, readOnly, updatedAt } = props;
  const [createdText, setCreatedText] = useState('');
  const [updatedText, setUpdatedText] = useState('');
  const { t } = useTranslation();
  const classes = useStyles(props);

  useEffect(() => {
    const formattedCreatedAt = new DateTime(new Date(createdAt)).formatDateTimeLocale();
    const formattedUpdatedAt = new DateTime(new Date(updatedAt)).formatDateTimeLocale();
    setCreatedText(`Created: ${formattedCreatedAt}`);
    setUpdatedText(`Modified: ${formattedUpdatedAt}`);
  }, [createdAt, t, updatedAt]);

  return (
    <Box className={classes.container}>
      <Typography variant="body2" className={classes.text}>
        {createdText}
      </Typography>
      <Box className={classes.spacer} />
      <Typography variant="body2" className={classes.text}>
        {updatedText}
      </Typography>
      {readOnly && (
        <Typography variant="body2" className={classes.readOnly}>
          {t('agreementSummary.readOnly')}
        </Typography>
      )}
    </Box>
  );
};

export default SecondaryText;
