import React from 'react';
import { useSelector } from 'react-redux';

import AccountMenu from 'src/components/AccountMenu/presenter';
import { ACCOUNT_MENU_TYPES } from 'src/constants';
import { currentDealership as dealershipSelector } from 'src/store/selectors/currentDealership';
import { user } from 'src/store/selectors/user';

import store from 'src/store';
import { logoutUser } from 'src/store/actions/auth';
import { ThunkDispatch } from 'src/store/types';

const AccountMenuContainer = () => {
  const currentDealership = useSelector(dealershipSelector);
  const userData = useSelector(user);

  const handleClick = ((type: ACCOUNT_MENU_TYPES) => {
    switch (type) {
      case (ACCOUNT_MENU_TYPES.LOG_OUT):
        (store.dispatch as (dispatch: ThunkDispatch) => void)(logoutUser());
        break;
    }
  });

  return (
    <AccountMenu
      currentDealership={currentDealership}
      handleClick={handleClick}
      userEmail={userData.email}
    />
  );
};

export default AccountMenuContainer;
