import { push } from 'connected-react-router';
import { ThunkDispatch } from 'src/store/types';
import { AUTHORIZED_USER } from 'src/store/actions/auth/constants';
import eventCreator from 'src/store/lib/eventCreator';
import { cache } from 'swr';
import { logout } from 'src/utils/auth/auth';
import { ROUTES } from 'src/constants';
import { TOGGLE_STATUSES } from 'src/store/lib/constants';

export const authenticateUserEvent = eventCreator(AUTHORIZED_USER);

export const isUserAuthenticated = (event: string) =>
  (dispatch: ThunkDispatch) => {
    dispatch(authenticateUserEvent.resolve(event));
  };

export const logoutUser = () =>
  (dispatch: ThunkDispatch) => {
    cache.clear();
    dispatch(isUserAuthenticated(TOGGLE_STATUSES.OFF));
    dispatch(push(ROUTES.LOGIN));
    logout();
  };
