import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Layout from 'src/components/Layout';
import { currentDealership as dealershipIdSelector } from 'src/store/selectors/currentDealership';
import { useDealershipFleets } from 'src/utils/hooks/fleets';
import Presenter from './presenter';

const DynamicContent = () => {
  const { t } = useTranslation();
  const dealership = useSelector(dealershipIdSelector);
  const { data, isLoading } = useDealershipFleets(dealership.id);

  if (isLoading) return <div />;

  return (
    <Layout maxWidth="md" title={t('dynamicContent.pageTitle')}>
      <Presenter fleets={data} dealershipName={dealership.name} />
    </Layout>
  );
};

export default DynamicContent;
