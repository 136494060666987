import { AnalyticsDomains } from 'src/constants/analytics';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type CommonColumn = {
  label: string;
  sortable?: boolean;
  id: string;
  format?: (value: string | number) => string;
  sortDirection?: SortDirection;
};

type TrackableColumn = {
  trackable: true;
  trackableId: string;
  analyticsGroup: AnalyticsDomains;
} | {
  trackable?: false;
  trackableId?: never;
  analyticsGroup?: never;
};

export type Column = CommonColumn & TrackableColumn;

export interface RowObj {
  [key: string]: any;
  id: string | number;
}

export interface SelectedRows {
  [key: string]: boolean | null;
}

export type FilterChangeEvent = (id: string, value: string) => void;

export type FilterOption = {
  id?: string;
  label: string;
  value: string;
};

export interface Filter {
  id: string;
  label: string;
  options: FilterOption[];
  onChange: FilterChangeEvent;
  active: boolean;
  value: string;
  analyticsDomain?: AnalyticsDomains;
}

export type RowActionType = number | string;

export interface RowAction {
  id?: string,
  type: RowActionType,
  label: string,
}

export enum CsvDownload {
  all = 'all',
  filtered = 'filtered',
}
