import * as cookies from 'browser-cookies';

import { LOCAL_STORAGE } from 'src/constants';
import { COOKIES } from 'src/utils/constants';
import { Authentication } from '../../store/types';
import { AuthLock, Auth0LockOptions } from './lock';

// TODO: Add tests for Authentication file.
export const getAuthentication = () => {
  const expiresAt = Number.parseInt(cookies.get(COOKIES.EXPIRES_AT), 10) || 0;
  const idToken = cookies.get(COOKIES.ID_TOKEN) || '';
  const authenticated = cookies.get(COOKIES.AUTHENTICATED) || false;

  return ({
    expiresAt,
    idToken,
    authenticated,
  });
};

export function setAuthentication(authentication: Authentication) {
  const options = { expires: 9999, domain: '', path: '/' };

  cookies.set(COOKIES.EXPIRES_AT, authentication.expiresAt.toString(10), options);
  cookies.set(COOKIES.ID_TOKEN, authentication.idToken, options);
  cookies.set(COOKIES.AUTHENTICATED, authentication.authenticated.toString(), options);
}

export const handleAuthentication = () => new Promise((resolve, reject) => {
  AuthLock.resumeAuth(window.location.hash, (error, authResult) => {
    if (authResult && authResult.accessToken) {
      setAuthentication({
        expiresAt: authResult.expiresIn * 1000 + Date.now(),
        idToken: authResult.idToken,
        authenticated: true,
      });
      resolve(getAuthentication());
    } else {
      reject(error);
    }
  });
});

export const authenticateUser = () => {
  AuthLock.show(Auth0LockOptions);
};

export const logout = () => {
  const cookieOptions = { domain: '', path: '/' };

  Object.values(COOKIES).forEach(cookie => {
    cookies.erase(cookie, cookieOptions);
  });
  localStorage.removeItem(LOCAL_STORAGE.CURRENT_DEALERSHIP);
};
