import axios from 'axios';
import { mutate } from 'swr';

import { baseUrl, getHeaders, getPagination } from 'src/utils/api/config';
import store from 'src/store';
import { ThunkDispatch } from 'src/store/types';
import { logoutUser } from 'src/store/actions/auth';
import { openSnackbar } from 'src/store/actions/snackbar';
import { SNACKBAR_TYPES } from 'src/constants';

const fetcher = (path: string, transform: (data: any) => any = null) => (
  axios
    .get(`${baseUrl}${path}`, { headers: getHeaders() })
    .then(({ data, headers }) => ({
      data: transform ? transform(data) : data,
      pagination: getPagination(headers),
    }))
    .catch(error => {
      if (error?.response?.status === 401) {
        (store.dispatch as (dispatch: ThunkDispatch) => void)(
          openSnackbar(SNACKBAR_TYPES.ERROR, '401 - Unauthorized. Logging you out.'),
        );
        (store.dispatch as (dispatch: ThunkDispatch) => void)(logoutUser());
      } else {
        throw error;
      }
    })
);

export const fetchAndCache = (key: string) => {
  const request = fetcher(key);
  mutate(key, request, false);
  return request;
};

export default fetcher;
