import {
  Box,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  title: {
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
}));

const Card = (props: Props) => {
  const classes = useStyles();
  const {
    className,
    title,
  } = props;
  return (
    <Paper className={className}>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      <Divider />
      <Box className={classes.content}>
        {props.children}
      </Box>
    </Paper>
  );
};

export default Card;
