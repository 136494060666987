import { AUTHORIZED_USER } from 'src/store/actions/auth/constants';
import {
  AuthenticatedState,
} from 'src/store/reducers/auth/types';

import { EVENTS, TOGGLE_STATUSES } from 'src/store/lib/constants';

type AuthEvent = {
  data?: any,
  type: string,
};

export const initialAuthenticatedState: AuthenticatedState = {
  status: TOGGLE_STATUSES.OFF,
  isAuthenticated: false,
};

const toggleAuthenticated = (
  state: AuthenticatedState = initialAuthenticatedState,
  event: AuthEvent,
): AuthenticatedState => {
  switch (event.type) {
    case `${AUTHORIZED_USER}/${EVENTS.RESOLVE}`:
      return {
        ...state,
        status: event.data,
        isAuthenticated: event.data === TOGGLE_STATUSES.ON,
      };
    default:
      return state;
  }
};

export const authenticatedReducer = (
  state: AuthenticatedState = initialAuthenticatedState,
  event: AuthEvent,
): AuthenticatedState => {
  switch (state.status) {
    case TOGGLE_STATUSES.OFF:
    case TOGGLE_STATUSES.ON:
      return toggleAuthenticated(state, event);
    default:
      return state;
  }
};

export default authenticatedReducer;
