import React, {
  Fragment,
  FocusEvent,
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Divider,
} from '@material-ui/core';
import { AssignmentTurnedIn, ErrorOutline } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Layout, TextInput } from 'dw-react-recipes';
import Button from 'src/components/Button';
import SkeletonLoader from 'src/components/SkeletonLoader';
import InfoCard from 'src/components/InfoCard';
import Card from 'src/pages/ManagedToggleGroups/Card';
import {
  ROUTES,
  SNACKBAR_TYPES,
  DUPLICATE_WARNING,
  TOGGLE_GROUP_INFO_1,
  TOGGLE_GROUP_INFO_2,
  TOGGLE_GROUP_INFO_3,
  MANAGED_TOGGLE_TEXT_1,
  MANAGED_TOGGLE_TEXT_2,
} from 'src/constants';
import Api from 'src/utils/api';
import { managedToggleGroup } from 'src/store/lib/endpoints';
import { openSnackbar } from 'src/store/actions/snackbar';
import { useManageToggleGroups, useManageToggleGroup } from 'src/utils/hooks/manageToggleGroups';
import styles from 'src/styles/variables.scss';
import AgreementSummary from 'src/components/AgreementToggle/AgreementSummary';

interface Params {
  groupId: string,
}

const handleEvent = (handler: (value: string) => void) =>
  (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    handler(e.target.value);
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    textField: {
      width: '48ch',
    },
    textLink: {
      textDecoration: 'none',
    },
    errorIcon: {
      color: styles.error50,
    },
    button: {
      textTransform: 'uppercase',
      marginLeft: theme.spacing(2),
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
    },
    boxRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
    },
    infoIcon: {
      color: styles.gray700,
      height: '100%',
      padding: theme.spacing(0.5),
      width: '100%',
    },
    emptyContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      backgroundColor: styles.blue100,
      color: styles.primaryBlue,
    },
    emptyText: {
      color: styles.blue800,
      marginLeft: theme.spacing(2),
    },
    addIcon: {
      color: styles.primaryBlue,
    },
  }));

const EditToggleGroup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: Params = useParams();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [hasDuplicate, setHasDuplicate] = useState(false);
  const [duplicateWarning, setDuplicateWarning] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { groupId } = params;
  const toggleGroupId = parseInt(groupId, 10);

  const {
    data: groups,
    hasError: hasErrorGroups,
    mutate: mutateGroups,
  } = useManageToggleGroups();

  const {
    data: group,
    hasError: hasErrorGroup,
    isLoading: isLoadingGroup,
    mutate: mutateGroup,
  } = useManageToggleGroup(toggleGroupId);

  useEffect(() => {
    if (!isLoadingGroup) {
      setName(group.name);
    }
  }, [isLoadingGroup]);

  if (hasErrorGroup || hasErrorGroups) {
    dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
  }

  const checkForDuplicate = (newName: string) => {
    const lowercaseNew = newName.toLowerCase().trim();
    return groups.some(group =>
      group.name?.toLowerCase().trim() === lowercaseNew);
  };

  const onNameChange = (newName: string) => {
    if (checkForDuplicate(newName)) {
      setDuplicateWarning(DUPLICATE_WARNING);
      setHasDuplicate(true);
    } else {
      setDuplicateWarning('');
      setHasDuplicate(false);
    }
    setName(newName);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await Api.put(managedToggleGroup(toggleGroupId), { name });
    } catch (error) {
      dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
      setIsSaving(false);
    } finally {
      setIsSaving(false);
      mutateGroup();
      mutateGroups();
    }
  };

  const handleFinish = () => {
    const path = ROUTES.MANAGED_TOGGLE_GROUPS.ROOT;
    history.push(path);
  };

  const ErrorIcon = (
    <ErrorOutline className={classes.errorIcon} />
  );

  const isSaveDisabled = name.trim().length === 0 || hasDuplicate || isSaving;

  return (
    <Layout title="Edit Managed Toggle Group" maxWidth="md">
      { (isLoadingGroup) ? <SkeletonLoader /> : (
        <>
          <InfoCard
            icon={<AssignmentTurnedIn className={classes.infoIcon} />}
            accentColor={styles.gray700}
            textLines={
              [TOGGLE_GROUP_INFO_1, TOGGLE_GROUP_INFO_2, TOGGLE_GROUP_INFO_3]
            }
          />
          <Card
            className={classes.card}
            title="TOGGLE GROUP NAME"
          >
            <Box className={classes.box}>
              <TextInput
                required
                className={classes.textField}
                label="Toggle Group Name"
                onChange={handleEvent(onNameChange)}
                value={name}
                helperText={duplicateWarning}
                suffix={hasDuplicate ? ErrorIcon : null}
              />
            </Box>
            <Divider />
            <Box className={classes.boxRight}>
              <Button
                className={classes.button}
                isDisabled={isSaving}
                isLoading={false}
                label="CLEAR CHANGES"
                type="submit"
                onClick={handleCancel}
                variant="text"
              />
              <Button
                className={classes.button}
                isDisabled={isSaveDisabled}
                isLoading={false}
                label="SAVE"
                type="submit"
                onClick={handleSave}
                variant="contained"
              />
            </Box>
          </Card>

          <Card
            className={classes.card}
            title="MANAGED TOGGLES"
          >
            <Box className={classes.card}>
              <Typography component="div">
                <p>{MANAGED_TOGGLE_TEXT_1}</p>
                <p>{MANAGED_TOGGLE_TEXT_2}</p>
                <a href="https://silvercar.atlassian.net/wiki/spaces/DCF/pages/2312568864/Dynamic+Content+Toggles" className={classes.textLink}>View a list of Agreement Summary Fees</a>
                and instructions on how to add them.
              </Typography>
            </Box>

            <Box className={classes.emptyContainer}>
              <InfoIcon />
              <Typography variant="body2" className={classes.emptyText}>
                To customize the Agreement Summary, add one or more Managed Toggles.
              </Typography>
            </Box>
            <Divider />
            <AgreementSummary
              toggleGroupId={toggleGroupId}
            />
          </Card>
        </>
      )}
      <Box className={classes.boxRight}>
        <Button
          className={classes.button}
          label="FINISH"
          type="button"
          onClick={handleFinish}
          variant="contained"
        />
      </Box>
    </Layout>
  );
};

export default EditToggleGroup;
