import React, { ReactNode } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = require('src/styles/variables.scss');

interface Props {
  id?: string
  variant: 'contained' | 'outlined' | 'text';
  label: string;
  onClick?: () => void;
  customWidth?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  startIcon?: ReactNode;
  className?: string;
  type?: 'button' | 'reset' | 'submit';
  size?: 'small' | 'medium' | 'large';
  wrapperClassName?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: (props: Props) => ({
      boxShadow: 'none',
      fontWeight: styles.fontSemibold,
      padding: '12px 16px',
      width: props.customWidth && props.customWidth,
    }),
    buttonLoading: {
      color: styles.primaryBlue,
      cursor: 'not-allowed',
      left: '50%',
      marginLeft: '-12px',
      marginTop: '-12px',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
    },
    contained: {
      '&:disabled': {
        backgroundColor: styles.gray400,
        color: styles.gray700,
        '&:hover': {
          backgroundColor: styles.gray400,
        },
      },
      '&:hover': {
        backgroundColor: styles.blue700,
      },
    },
    outlined: {
      border: `1.5px solid ${styles.primaryBlue}`,
      '&:disabled': {
        border: `1.5px solid ${styles.gray400}`,
        color: styles.gray700,
        '&:hover': {
          border: `1.5px solid ${styles.gray400}`,
          color: styles.gray700,
        },
      },
      '&:hover': {
        border: `1.5px solid ${styles.primaryBlue}`,
      },
    },
    sizeSmall: {
      fontSize: '13px',
      lineHeight: '22px',
      letterSpacing: '0.55px',
    },
    text: {
      '&:disabled': {
        backgroundColor: styles.gray400,
        color: styles.gray700,
        '&:hover': {
          backgroundColor: styles.gray400,
        },
      },
    },
    wrapper: {
      display: 'inline',
      position: 'relative',
    },
  }));

const ButtonContainer = (props: Props) => {
  const {
    variant,
    label,
    isDisabled,
    isLoading,
    startIcon,
    onClick,
    className,
    type,
    size,
    wrapperClassName,
  } = props;
  const classes = useStyles(props);
  const classNames = `${className || ''} ${classes.button} ${classes[variant]}`;
  const wrapperClassNames = `${wrapperClassName || ''} ${classes.wrapper}`;

  return (
    <Box className={wrapperClassNames}>
      <Button
        classes={{
          sizeSmall: classes.sizeSmall,
        }}
        className={classNames}
        variant={variant}
        onClick={onClick}
        color="primary"
        disabled={isDisabled || isLoading}
        startIcon={startIcon}
        type={type}
        size={size}
      >
        {label}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
    </Box>
  );
};

export default ButtonContainer;
