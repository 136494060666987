import { PAGINATION } from 'src/store/lib/constants';

export const getNewPage = (page: number, directionToCache: string) => {
  switch (directionToCache) {
    case PAGINATION.DIRECTION.NEXT:
      return page + 1;
    case PAGINATION.DIRECTION.BACK:
      return page - 1;
    default:
      return page;
  }
};
