import { UPLOAD_FORM } from 'src/store/actions/forms/constants';
import { EVENTS, STATUSES } from 'src/store/lib/constants';
import { UploadFormEvent, UploadFormState } from 'src/store/reducers/forms/types';

export const initialUploadFormState: UploadFormState = {
  status: STATUSES.IDLE,
  error: null,
};

const idleReducer = (
  state: UploadFormState = initialUploadFormState,
  event: UploadFormEvent,
): UploadFormState => {
  const { type } = event;

  switch (type) {
    case `${UPLOAD_FORM}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    default:
      return state;
  }
};

const loadingReducer = (
  state: UploadFormState = initialUploadFormState,
  event: UploadFormEvent,
): UploadFormState => {
  const { error, type } = event;

  switch (type) {
    case `${UPLOAD_FORM}/${EVENTS.RESOLVE}`:
      return {
        status: STATUSES.SUCCESS,
        error: null,
      };
    case `${UPLOAD_FORM}/${EVENTS.REJECT}`:
      return {
        ...state,
        status: STATUSES.FAILURE,
        error,
      };
    default:
      return state;
  }
};

const successFailureReducer = (
  state: UploadFormState = initialUploadFormState,
  event: UploadFormEvent,
): UploadFormState => {
  const { type } = event;

  switch (type) {
    case `${UPLOAD_FORM}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    default:
      return state;
  }
};

const upload = (
  state: UploadFormState = initialUploadFormState,
  event: UploadFormEvent,
): UploadFormState => {
  const { status } = state;

  switch (status) {
    case STATUSES.IDLE:
      return idleReducer(state, event);
    case STATUSES.LOADING:
      return loadingReducer(state, event);
    case STATUSES.SUCCESS:
    case STATUSES.FAILURE:
      return successFailureReducer(state, event);
    default:
      return state;
  }
};

export default upload;
