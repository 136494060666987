import React from 'react';
import { PageTitle } from 'dw-react-recipes';
import { useTranslation } from 'react-i18next';

const NotFoundContainer = () => {
  const { t } = useTranslation();
  return (
    <PageTitle
      title={t('errors.notFound')}
    />
  );
};

export default NotFoundContainer;
