export const unauthorizedError = 'This operation is not authorized.';

export const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/#/callback`;

export const COOKIES = {
  EXPIRES_AT: 'expiresAt',
  AUTHENTICATED: 'authenticated',
  ID_TOKEN: 'idToken',
  EMAIL: 'email',
};
