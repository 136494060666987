import { createSelector } from 'reselect';

import { UserStore } from 'src/store/reducers/user/types';

export const userState = (state: UserStore) => state.user;

export const user = createSelector(
  userState,
  userState => userState,
);
