/* eslint-disable no-underscore-dangle */
import { compose, createStore, applyMiddleware } from 'redux';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { rootReducer } from 'src/store/reducers';
import { isUserAuthenticated } from 'src/store/actions/auth';
import { TOGGLE_STATUSES } from 'src/store/lib/constants';

import { handleAuthentication } from '../utils/auth/auth';
import { Authentication, ThunkDispatch } from './types';
import { REDIRECT_URI } from '../utils/constants';

export const history = createHashHistory();

const middleware = applyMiddleware(
  thunk,
  routerMiddleware(history),
);

interface ReduxWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION__: any,
}

const devTools = (window as unknown as ReduxWindow).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as unknown as ReduxWindow).__REDUX_DEVTOOLS_EXTENSION__()
  : (x: any) => x;

const store = createStore(
  rootReducer(history),
  compose(
    middleware,
    devTools,
  ),
);

store.subscribe(() => {
  // TODO: Extract window location check to helper method.
  if (window.location.href.includes('access_token')) {
    handleAuthentication()
      .then((response: Authentication) => {
        if (response && response.idToken) {
          (store.dispatch as (dispatch: ThunkDispatch) => void)(isUserAuthenticated(TOGGLE_STATUSES.ON));
          setTimeout(() => {
            window.location.replace(REDIRECT_URI);
          });
        }
      });
  }
});

export default store;
