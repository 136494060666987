import { ThunkDispatch } from 'src/store/types';
import { USER_EMAIL } from 'src/store/lib/constants';

export const setUserEmail = (email: string) =>
  (dispatch: ThunkDispatch) => {
    dispatch({
      type: USER_EMAIL,
      data: email,
    });
  };
