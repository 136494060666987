import React, { ChangeEvent, ReactNode } from 'react';
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ArrowDropDown } from '@material-ui/icons';

import { SelectOptionType } from 'src/store/types';
import { AnalyticsDomains } from 'src/constants/analytics';

const styles = require('src/styles/variables.scss');

export interface Props {
  className?: string;
  fullWidth?: boolean;
  hasError?: boolean;
  helperText?: string;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  margin?: 'dense' | 'none';
  maxMenuHeight?: number;
  minWidth?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onError?: (error: string) => void;
  options: Array<SelectOptionType>;
  required?: boolean;
  size?: 'small' | 'medium';
  startAdornment?: ReactNode;
  tooltipText?: string;
  value?: string | number;
  analyticsDomain?: AnalyticsDomains;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: (props: Props) => ({
      color: props.isDisabled ? styles.blue300 : styles.primaryBlue,
    }),
    fullWidthLabel: {
      width: '100%',
    },
    spinner: {
      color: styles.primaryBlue,
      cursor: 'not-allowed',
      position: 'absolute',
      right: '3%',
      top: '30%',
      zIndex: 1,
    },
    textField: (props: Props) => ({
      minWidth: props.minWidth || '200px',
    }),
  }));

const SelectInput = (props: Props) => {
  const {
    className = '',
    fullWidth,
    hasError,
    helperText,
    isDisabled,
    isLoading,
    label,
    id,
    margin,
    maxMenuHeight = 300,
    name,
    onChange,
    options,
    required,
    size = 'medium',
    startAdornment,
    tooltipText = '',
    value,
    analyticsDomain,
  } = props;
  const classes = useStyles(props);
  const textFieldId = analyticsDomain ? `${analyticsDomain}-${id}` : id;
  const selectedValue = value;

  const Spinner = () => (
    <CircularProgress size={20} className={classes.spinner} />
  );

  const handleDisabledOptions = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && event.target.value !== '') {
      onChange(event);
    }
  };

  const getSelectOptions = (selectOptions: SelectOptionType[]) =>
    selectOptions.map(({ label, value, disabled = false }) => {
      const itemId = analyticsDomain ? `${analyticsDomain}-item-${value}` : null;
      const title = label[1] && !disabled ? tooltipText : '';
      const optionProps = {
        id: itemId,
        disabled,
        key: value,
        value: disabled ? '' : value,
      };

      if (value === selectedValue) {
        return (
          <MenuItem {...optionProps}>
            {label}
          </MenuItem>
        );
      }

      return (
        <MenuItem {...optionProps}>
          <Tooltip title={title} placement="bottom-end">
            <Box className={classes.fullWidthLabel}>{label}</Box>
          </Tooltip>
        </MenuItem>
      );
    });

  return (
    <TextField
      className={`${classes.textField} ${className}`}
      disabled={isDisabled}
      error={hasError}
      helperText={helperText}
      id={textFieldId}
      InputProps={{ startAdornment }}
      label={label}
      select
      value={value}
      onChange={handleDisabledOptions}
      required={required}
      SelectProps={{
        classes: {
          icon: classes.icon,
        },
        IconComponent: isLoading ? Spinner : ArrowDropDown,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: maxMenuHeight,
            },
          },
        },
      }}
      size={size}
      variant="outlined"
      name={name}
      fullWidth={fullWidth}
      margin={margin}
    >
      {getSelectOptions(options)}
    </TextField>
  );
};

export default SelectInput;
