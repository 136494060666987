export const documents = '/v1/documents';

export const managedToggleGroups = '/v4/admin/toggle_groups';

export const managedToggleGroup = (groupId: number): string => (
  `/v4/admin/toggle_groups/${groupId}`
);

export const agreementToggles = (groupId: number): string => (
  `/v4/admin/toggle_groups/${groupId}/toggles`
);

export const reservation = (dealershipId: number, contractId: string): string => (
  `/v3/locations/${dealershipId}/reservations/${contractId}`
);

export const reservationModify = (contractId: string): string => (
  `/v4/admin/contracts/${contractId}/modify`
);

export const managedToggle = (toggleId: number): string => (
  `/v4/admin/managed_toggles/${toggleId}`
);

export const managedTogglesActivation = (toggleId: number, dealershipId: string): string => (
  `/v4/admin/managed_toggles/${toggleId}/dealerships/${dealershipId}/activations`
);

export const dealerships = (page: number, perPage: number): string => (
  `/v4/admin/dealerships?page=${page}&per_page=${perPage}`
);

export const dealershipSearch = (
  query: string, page: number, perPage: number,
): string => (
  `/v4/admin/dealerships/search?q=${query}&page=${page}&per_page=${perPage}`
);

export const currentDealership = (id: number): string => (
  `/v4/admin/dealerships/${id}`
);

export const dealershipFleets = (id: number): string => (
  `/v4/admin/dealerships/${id}/fleets`
);

export const fleetToggleVariables = (id: string): string => (
  `/v4/admin/fleets/${id}/toggles/variables`
);

export const reindexVehicle = (): string => (
  '/v4/admin/vehicles/reindex'
);
