import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { PAGINATION } from 'src/store/lib/constants';
import { useDispatch, useSelector } from 'react-redux';

import { getDealership } from 'src/store/actions/currentDealership';
import {
  dealerships as dealershipsUrl,
  dealershipSearch as dealershipSearchUrl,
} from 'src/store/lib/endpoints';
import { hasCurrentDealership as hasDealershipSelector } from 'src/store/selectors/currentDealership';
import { fetchAndCache } from 'src/utils/api/fetcher';
import { getNewPage } from 'src/utils/pagination';
import useDealerships from 'src/utils/hooks/dealerships';
import { Dealership } from 'src/utils/hooks/dealerships/types';
import SelectLocation from './presenter';

const SelectLocationContainer = () => {
  const dispatch = useDispatch();
  const hasCurrentDealership = useSelector(hasDealershipSelector);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PAGINATION.PER_PAGE[0]);
  const {
    data,
    pagination,
    isLoading,
    hasError,
    mutate,
  } = useDealerships({
    page,
    perPage,
    preventFetch: false,
    search: searchValue,
  });

  useEffect(() => {
    const newUrl = searchValue
      ? dealershipSearchUrl(searchValue, 2, perPage)
      : dealershipsUrl(2, perPage);
    fetchAndCache(newUrl);
  }, [perPage, searchValue]);

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
    setPage(1);
  };

  const handlePageChange = (nextPage: number, nextPerPage: number, directionToCache: string) => {
    const cachedPage = getNewPage(nextPage, directionToCache);
    const newUrl = searchValue
      ? dealershipSearchUrl(searchValue, cachedPage, perPage)
      : dealershipsUrl(cachedPage, perPage);

    if (cachedPage !== 0) fetchAndCache(newUrl);
    setPage(nextPage);
    setPerPage(nextPerPage);
  };

  const handleRowClick = useCallback(
    (data: Dealership) => {
      dispatch(getDealership(data.id));
    },
    [dispatch],
  );

  return (
    <SelectLocation
      dealerships={data}
      handlePageChange={handlePageChange}
      handleRefresh={mutate}
      handleRowClick={handleRowClick}
      handleSearchChange={handleSearchChange}
      hasCurrentDealership={hasCurrentDealership}
      hasError={hasError}
      isLoading={isLoading}
      page={page}
      perPage={perPage}
      searchValue={searchValue}
      total={pagination?.total || 0}
    />
  );
};

export default SelectLocationContainer;
