import { createSelector } from 'reselect';
import { CurrentDealershipStore } from 'src/store/reducers/currentDealership/types';

export const currentDealershipState = (state: CurrentDealershipStore) => state.currentDealership;

export const currentDealership = createSelector(
  currentDealershipState,
  ({ data }) => data,
);

export const hasCurrentDealership = createSelector(
  currentDealershipState,
  ({ data }) => Boolean(data),
);

export const currentDealershipName = createSelector(
  currentDealershipState,
  ({ data }) => {
    if (data && data.name) {
      return data.name.trim();
    }
    return '';
  },
);

export const currentDealershipId = createSelector(
  currentDealershipState,
  ({ data }) => data?.id,
);

export const salesforceId = createSelector(
  currentDealershipState,
  ({ data }) => {
    if (data && data.salesforce_id) {
      return data.salesforce_id.trim();
    }
    return '';
  },
);

export const insuranceFormProvider = createSelector(
  currentDealershipState,
  ({ data }) => {
    if (data && data.insurance_form_provider) {
      return data.insurance_form_provider.trim();
    }
    return '';
  },
);

export const isMetric = createSelector(
  currentDealershipState,
  ({ data }) => data?.metric,
);

export const fuelSuffix = createSelector(
  isMetric,
  isMetric => (isMetric ? 'volume.litre' : 'volume.gallon'),
);

export const mileageSuffix = createSelector(
  isMetric,
  isMetric => (isMetric ? 'mileage.kilometersShort' : 'mileage.milesShort'),
);
