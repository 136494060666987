import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Box,
  Theme,
} from '@material-ui/core';
import { Layout } from 'dw-react-recipes';
import Button from 'src/components/Button';
import TextInput from 'src/components/Input/TextInput';
import Api from 'src/utils/api';
import { reindexVehicle } from 'src/store/lib/endpoints';
import { openSnackbar } from 'src/store/actions/snackbar';
import { SNACKBAR_TYPES } from 'src/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      alignItems: 'center',
    },
    vinInput: {
      width: 225,
      marginRight: theme.spacing(1),
    },
  }));

const ReindexVehicles = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [vinText, setVinText] = useState<string>('');
  const [error, setError] = useState(null);

  const validateVin = (vin: string) => {
    const matches = vin.match(/^[A-Z0-9]{17}$/) !== null;
    setError(matches ? null : t('reindexPage.vinError'));
    return matches;
  };

  const onVinTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVinText(event.target.value);

    if (event.target.value.length === 17) {
      validateVin(event.target.value);
    }
  };

  const onReindexClick = () => {
    if (!validateVin(vinText)) return;

    setLoading(true);

    Api.post(reindexVehicle(), { vin: vinText })
      .then(() => {
        dispatch(openSnackbar(SNACKBAR_TYPES.SUCCESS, t('reindexPage.success')));
      })
      .catch(() => {
        dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout title={t('reindexPage.title')} maxWidth="md">
      <Box className={classes.box}>
        <TextInput
          id="vinInput"
          isDisabled={loading}
          label={t('reindexPage.vinInput')}
          onChange={onVinTextChange}
          className={classes.vinInput}
          hasError={!!error}
          helperText={error}
          maxLength={17}
        />
        <Button
          isDisabled={loading || error || vinText.length < 17}
          isLoading={loading}
          label={t('reindexPage.reindexButton')}
          onClick={onReindexClick}
          variant="contained"
        />
      </Box>
    </Layout>
  );
};

export default ReindexVehicles;
