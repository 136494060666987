import React, { ReactNode, MouseEvent } from 'react';
import {
  createStyles,
  makeStyles,
  IconButton as MaterialIconButton,
  Theme,
} from '@material-ui/core';
import { AnalyticsDomains } from 'src/constants/analytics';

interface Props {
  ariaLabel: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  icon: ReactNode;
  onClick: (e: MouseEvent) => void;
  size?: 'small' | 'medium';
  disabled?: boolean;
  className?: string;
  id?: string;
  analyticsDomain?: AnalyticsDomains;
  trackableId?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    padding: theme.spacing(1),
  },
}));

const IconButton = (props: Props) => {
  const {
    id,
    icon,
    ariaLabel,
    color = 'primary',
    onClick,
    size = 'small',
    disabled,
    className,
    analyticsDomain,
    trackableId,
  } = props;
  const classes = useStyles(props);
  const classNames = `${className || ''} ${classes.button}`;
  const dataAnalytics = analyticsDomain ? `${analyticsDomain}-${trackableId}-icon-button` : null;

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <MaterialIconButton
      aria-label={ariaLabel}
      onClick={handleClick}
      size={size}
      disabled={disabled}
      color={color}
      className={classNames}
      id={id}
      data-analytics-domain={dataAnalytics}
    >
      {icon}
    </MaterialIconButton>
  );
};

export default IconButton;
