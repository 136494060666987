import React from 'react';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { SNACKBAR_HIDE_DURATION } from 'src/constants';
import { getSnackbarIcon } from 'src/utils/icons';
import { ClassNameType } from 'src/store/types';
import { SnackbarState } from 'src/store/reducers/snackbar/types';

import styles from 'src/styles/variables.scss';

interface Props {
  snackbar: SnackbarState;
  closeSnackbar: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeIcon: {
    color: styles.white,
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  error: {
    backgroundColor: styles.error50,
  },
  info: {
    backgroundColor: styles.gray800,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: styles.white,
  },
  messageIcon: {
    color: styles.white,
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
    width: theme.spacing(2.5),
  },
  messageText: {
    color: styles.white,
  },
  success: {
    backgroundColor: styles.success50,
  },
  warning: {
    backgroundColor: styles.warning50,
  },
}));

const SnackbarPresenter = (props: Props) => {
  const { snackbar, closeSnackbar } = props;
  const classes: ClassNameType = useStyles(props);
  const { isOpen, type, message } = snackbar;

  return (
    <Snackbar
      className={classes.container}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={message}
      open={isOpen}
      autoHideDuration={SNACKBAR_HIDE_DURATION}
      disableWindowBlurListener
      onClose={closeSnackbar}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby="client-snackbar"
        message={(
          <div
            className={classes.message}
            id="client-snackbar"
          >
            {getSnackbarIcon(classes.messageIcon, type)}
            <Typography
              className={classes.messageText}
              variant="subtitle1"
            >
              {message}
            </Typography>
          </div>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            onClick={closeSnackbar}
          >
            <Close className={classes.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SnackbarPresenter;
