import React, { ChangeEvent, MouseEvent } from 'react';
import {
  createStyles,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TablePaginationActions from 'src/components/Table/TablePaginationActions';
import { PAGINATION } from 'src/store/lib/constants';

const useStyles = makeStyles(() => createStyles({
  footer: {
    overflow: 'visible',
  },
  select: {
    fontSize: '14px',
    letterSpacing: '0.3px',
    lineHeight: '21px',
  },
}));

interface Props {
  dataTotal: number;
  onPageChange: (page: number, perPage: number, directionToCache?: string) => void;
  page: number;
  perPage: number;
}

const Pagination = (props: Props) => {
  const {
    dataTotal,
    page,
    perPage,
    onPageChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    directionToCache?: string,
  ) => {
    // offset the zero-based index of TablePagination
    onPageChange(newPage + 1, perPage, directionToCache);
  };

  const handleChangePerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const perPage = parseInt(event.target.value, 10);

    if (dataTotal > 0) {
      onPageChange(1, perPage);
    }
  };

  return (
    <TablePagination
      ActionsComponent={TablePaginationActions}
      classes={{ root: classes.footer, select: classes.select }}
      component="div"
      count={dataTotal}
      labelRowsPerPage={t('table.pagination.rowsPerPage')}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangePerPage}
      page={page}
      rowsPerPage={perPage}
      rowsPerPageOptions={PAGINATION.PER_PAGE}
      SelectProps={{
        inputProps: { 'aria-label': t('table.pagination.rowsPerPage') },
        native: true,
      }}
    />
  );
};

export default Pagination;
