import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormCard, Layout } from 'dw-react-recipes';
import { ROUTES, SNACKBAR_TYPES } from 'src/constants';
import Button from 'src/components/Button';
import SkeletonLoader from 'src/components/SkeletonLoader';
import { openSnackbar } from 'src/store/actions/snackbar';
import { useManageToggleGroups } from 'src/utils/hooks/manageToggleGroups';
import GroupCard from 'src/pages/ManagedToggleGroups/GroupCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(6),
    },
    buttonContainer: {
      alignSelf: 'flex-end',
    },
    container: {
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    cardContainer: {
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));

const ManagedToggleGroups = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    data: groups,
    hasError,
    isLoading,
  } = useManageToggleGroups();

  if (hasError) {
    dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
  }

  const createGroup = useCallback(
    () => {
      const path = ROUTES.MANAGED_TOGGLE_GROUPS.NEW;
      history.push(path);
    },
    [history],
  );

  const editGroup = (id: number) => {
    let path = ROUTES.MANAGED_TOGGLE_GROUPS.EDIT;
    path = path.replace(':groupId', id.toString());
    history.push(path);
  };

  return (
    <Layout title={t('managedToggleGroups.title')} maxWidth="md">
      {isLoading ? <SkeletonLoader /> : (
        <FormCard title={t('managedToggleGroups.cardTitle')}>
          <Typography variant="body2">{t('managedToggleGroups.cardBody')}</Typography>
          <Grid container className={classes.container}>
            <Grid
              item
              container
              spacing={3}
              className={classes.cardContainer}
            >
              {groups && groups.map(group => (
                <Grid
                  key={group.id}
                  item
                  sm={6}
                  xs={12}
                >
                  <GroupCard
                    editable
                    deletable={false}
                    name={group.name}
                    onEditClick={() => editGroup(group.id)}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4} className={classes.buttonContainer}>
              <Button
                customWidth="100%"
                className={classes.button}
                label={t('managedToggleGroups.buttons.add')}
                variant="contained"
                onClick={createGroup}
              />
            </Grid>
          </Grid>
        </FormCard>
      )}
    </Layout>
  );
};

export default ManagedToggleGroups;
