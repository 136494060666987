export const translationEN = {
  contractsPage: {
    description: 'Use of this tool is intended for closed contracts only. Do not use this feature on open contracts. Search a contract ID to find a contract to modify.',
    title: 'Modify Contract',
    searchButton: 'Search',
    searchInput: 'Contract ID',
    success: 'Contract modified.',
    contractCard: {
      contractId: 'Contract ID: ',
      confirmationToken: 'Confirmation Token',
      status: 'Status',
      actualPickup: 'Actual Pickup',
      actualDropoff: 'Actual Dropoff',
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  errors: {
    formUpload: 'There was a problem uploading the file. Please try again.',
    generic: 'Something went wrong. Please refresh or try again later.',
    notFound: '404 - Page not found',
    refresh: 'Refresh',
    goHome: 'Go Home',
  },
  formsPage: {
    title: 'Insurance Provider Forms',
    addNewForm: 'Add New Form',
    bannerTitle: 'Forms Management',
  },
  formsMappingPage: {
    title: 'Map Form',
    guidelineText: 'Hover over the form to get X & Y-Values. Enter those values in the suggested fields to map the form. This must be completed in one session.',
  },
  formsUploadPage: {
    title: 'Add New Form',
    subtitle: 'Upload Form',
    guidelineText: 'Form Upload Guidelines:',
    uploadGuidelines: [
      'Document cannot contain customer information',
      'Required format: PDF | Maximum file size: 5MB',
      'Recommended dimensions: 8.5” x 11”',
    ],
    modals: {
      title: 'Are you sure you want to cancel?',
      body: 'If you leave this page, the form will not be uploaded and all changes will be lost.',
      cancel: 'Return to Page',
      confirm: 'Discard Form',
    },
    cancel: 'Cancel',
    upload: 'Upload Form',
    notes: {
      label: 'Notes',
      optionalLabel: '(Optional)',
      placeholder: 'Write any notes you have for this form.',
    },
    inputs: {
      oemsLabel: 'Select OEM(s)',
      providerLabel: 'Select Provider',
      stateLabel: 'Select State(s)',
    },
  },
  homePage: {
    title: 'Welcome to Dealerware Mission Control',
    desc: 'Welcome to Dealerware Mission Control, a place where the Customer Success team can manage dealership, dealer group, and OEM specific settings and unlock the full potential of mobility at the dealership.',
    toggleGroupsTitle: 'Toggle Groups & Managed Toggles',
    toggleGroupsP1: 'Use Dealerware Mission Control to create a Toggle Group and add Managed Toggles.',
    toggleGroupsP2: `A Toggle Group is a series of Managed Toggles which appear on the Dealerware Agreement Summary as
      legal Agreement Statements.`,
    toggleGroupsP3: `Managed Toggles are provided to the Customer Success team by a dealership representative, and cannot be modified
    via Dealerware. Managed Toggles must be maintained in Dealerware Mission Control. All changes should first be
    approved by a dealership.`,
    dealershipsTitle: 'Dealerships',
    dealershipsP1: `Use Dealerware Mission Control to assign a Toggle Group to a dealership or configure dealership settings such as
    Telematics Provider and Prevailing Rate.`,
    dealershipsP2: `By assigning a Toggle Group to a dealership, all Managed Toggles within the Toggle Group will appear on the
    Agreement Summary during the Dealerware Driver start contract flow.`,

  },
  loginPage: {
    title: 'Admin Portal',
    label: 'Login',
  },
  navItems: {
    contracts: 'Contracts',
    dealerships: 'Dealerships',
    home: 'Home',
    logout: 'Logout',
    managed_toggle_groups: 'Managed Toggle Groups',
    reindex_vehicles: 'Reindex Vehicles',
    noDealerships: 'No location selected',
    dynamic_content: 'Dynamic Content',
  },
  managedToggleGroups: {
    buttons: {
      add: 'Create New Toggle Group',
      cancel: 'Cancel',
      save: 'Create Group',
    },
    cardTitle: 'Current Toggle Groups',
    cardBody: 'Create a new Toggle Group or edit an existing one below. Each Toggle Group contains the Managed Toggles that will appear on the Agreement Summary of the assigned dealership.',
    title: 'Toggle Groups',
    createToggleGroup: {
      title: 'Add New Toggle Group',
      cardTitle: 'New Toggle Group',
      textInputLabel: 'Toggle Group Name',
    },
    editToggleGroup: {
      title: 'Edit Managed Toggle Group',
    },
  },
  reindexPage: {
    title: 'Reindex Vehicle',
    vinInput: 'Vehicle VIN',
    reindexButton: 'Reindex',
    success: 'Vehicle reindexed!',
    vinError: 'Invalid VIN',
  },
  selectLocation: {
    label: 'Select Location',
    searchText: 'Search',
  },
  table: {
    actionable: {
      label: 'Action',
      button: 'Action',
    },
    download: {
      button: 'Download CSV',
      all: 'Download All (CSV)',
      filtered: 'Download Filtered Results (CSV)',
    },
    expandable: {
      button: 'Expand',
    },
    pagination: {
      rowsPerPage: 'Rows per page:',
    },
    record: {
      singular: '{{count}} Record',
      plural: '{{count}} Records',
    },
    search: {
      clear: 'clear search',
      search: 'search',
    },
  },
  dynamicContent: {
    pageTitle: 'Manage Dynamic Content',
    settingsTitle: 'Dynamic Content Settings',
    vehicleGroupDropdown: 'Select Vehicle Group',
    telematicsSettings: 'Telematics Settings',
    dealerships: 'Dealerships',
    button: 'Save',
    amount: 'Amount',
    successMessage: 'Content Updated Successfully',
    errorMessage: 'Error updating content',
    errorOnLoadingToggles: 'Error loading toggle variables',
  },
};
