import React, { ComponentClass, StatelessComponent } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { getAuthentication } from 'src/utils/auth/auth';

type RouteComponent = StatelessComponent<RouteComponentProps<{}>> | ComponentClass<any>;

type Props = {
  exact?: boolean,
  component: RouteComponent;
  path: string;
  render?: any;
  rest?: any;
};

const PrivateRoute = ({
  component: RouteComponent,
  exact,
  path,
  ...rest
}: Props) => {
  const { idToken, expiresAt } = getAuthentication();
  const notExpired = Date.now() < expiresAt;
  const render = (props: any) => idToken && notExpired && <RouteComponent {...props} />;
  return idToken && notExpired
    ? <Route exact={exact} path={path} render={render} {...rest} />
    : <Redirect to="/login" />;
};

export default PrivateRoute;
