import { useEffect } from 'react';
import cookies from 'browser-cookies';
import jwtDecode from 'jwt-decode';

import { setUserEmail } from 'src/store/actions/user';
import { JWT } from 'src/utils/auth/types';
import { notifyBugsnag } from 'src/utils/bugsnag';
import { COOKIES } from 'src/utils/constants';
import { useDispatch } from 'react-redux';

const useJwtDecoder = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const idToken = cookies.get(COOKIES.ID_TOKEN);
    if (idToken) {
      try {
        const decodedJwt = jwtDecode<JWT>(idToken);
        const { email } = decodedJwt;
        dispatch(setUserEmail(email));
      } catch (e) {
        notifyBugsnag(e);
      }
    }
  }, [dispatch]);
};

export default useJwtDecoder;
