import useSWR from 'swr';

import fetcher from 'src/utils/api/fetcher';
import { agreementToggles } from 'src/store/lib/endpoints';
import { Payload } from 'src/utils/hooks/agreementToggles/types';

export const useAgreementsToggle = (id:number): Payload => {
  const { data: response, error, mutate } = useSWR(agreementToggles(id), fetcher);
  const data = response ? response.data : null;

  return {
    data,
    hasError: Boolean(error),
    isLoading: !error && !data,
    mutate,
  };
};
