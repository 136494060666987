import React from 'react';
import {
  Typography, Chip, makeStyles, createStyles, Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = require('src/styles/variables.scss');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: styles.blue800,
      height: '20px',
      width: '50px',
      borderRadius: '10px',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    label: {
      color: styles.white,
      textTransform: 'uppercase',
      lineHeight: 1.29,
      padding: 0,
    },
  }));

const BetaTag = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const label = (
    <Typography
      className={classes.label}
      variant="subtitle2"
    >
      {t('beta')}
    </Typography>
  );

  return (
    <Chip
      component="div"
      classes={classes}
      label={label}
    />
  );
};

export default BetaTag;
