import { AxiosError } from 'axios';
import { unauthorizedError } from 'src/utils/constants';
import { notifyBugsnag } from 'src/utils/bugsnag';

class HttpError extends Error {
  private status: number;

  constructor(message: any, status: number) {
    super(message);
    this.status = status;
  }
}

const formatError = ({ status, data }: any) => {
  let message = `Request failed with status code ${status}`;
  if (status >= 400 && status < 500) {
    message = data.message;
  }
  return new HttpError(message, status);
};

export const handleError = (err: AxiosError, isPost = false) => {
  if (err && err.response && err.response.status && err.response.status === 401) {
    return new Error(unauthorizedError);
  }

  notifyBugsnag(err);

  if (isPost) {
    return formatError(err.response);
  }

  return err;
};
