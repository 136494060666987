import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'dw-react-recipes';
import Api from 'src/utils/api';
import { currentDealership as dealershipSelector } from 'src/store/selectors/currentDealership';
import { reservation, reservationModify } from 'src/store/lib/endpoints';
import { openSnackbar } from 'src/store/actions/snackbar';
import { SNACKBAR_TYPES } from 'src/constants';
import { Contract, ContractResponse } from 'src/pages/Contracts/types';
import Presenter from './presenter';

const Contracts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [contract, setContract] = useState(null);
  const currentDealership = useSelector(dealershipSelector);

  const convertUTCToDealer = (datetime: string): string => (
    new Date(datetime).toLocaleString('en-CA', {
      timeZone: currentDealership.time_zone,
      hour12: false,
    }).replace(', ', 'T')
  );

  const parseContractResponse = (res: ContractResponse): Contract => ({
    id: res.id,
    confirmation_token: res.confirmation_token,
    current_state: res.current_state,
    actual_pickup: res.actual_pickup ? convertUTCToDealer(res.actual_pickup) : '',
    actual_dropoff: res.actual_dropoff ? convertUTCToDealer(res.actual_dropoff) : '',
  });

  const onSearchClick = () => {
    if (searchText.length < 1) return;
    setIsLoading(true);

    Api.get(reservation(currentDealership.id, searchText))
      .then(res => {
        setContract(parseContractResponse(res));
      }).catch(() => {
        dispatch(openSnackbar(
          SNACKBAR_TYPES.ERROR,
          t('errors.generic'),
        ));
        setContract(null);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText(text);
  };

  const onSaveClick = (newContract: Contract) => {
    setIsLoading(true);
    Api.put(reservationModify(newContract.id.toString()), newContract)
      .then(() => {
        setContract(newContract);
        dispatch(openSnackbar(
          SNACKBAR_TYPES.SUCCESS,
          t('contractsPage.success'),
        ));
      }).catch(() => {
        dispatch(openSnackbar(
          SNACKBAR_TYPES.ERROR,
          t('errors.generic'),
        ));
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout title={t('contractsPage.title')} maxWidth="md">
      <Presenter
        contract={contract}
        loading={isLoading}
        onSaveClick={onSaveClick}
        onSearchClick={onSearchClick}
        onSearchTextChange={onSearchTextChange}
      />
    </Layout>
  );
};

export default Contracts;
