import useSWR from 'swr';
import fetcher from 'src/utils/api/fetcher';
import { dealershipFleets } from 'src/store/lib/endpoints';
import { PayloadGroups } from 'src/utils/hooks/manageToggleGroups/types';

export const useDealershipFleets = (id: number): PayloadGroups => {
  const url = dealershipFleets(id);
  const { data: response, error, mutate } = useSWR(url, fetcher);
  const data = response ? response.data : null;

  return {
    data,
    hasError: Boolean(error),
    isLoading: !error && !data,
    mutate,
  };
};
