import React from 'react';
import { Grid } from '@material-ui/core';
import FleetTextInput from './FleetTextInput';
import { FleetInputProperties, FleetTextInputProps } from './types';

const FleetTextInputGroup = (props: FleetTextInputProps) => {
  const {
    handleInputChange,
    isSelectedFleet,
    handleOnBlur,
    fleetToggleValues,
  } = props;

  const fleetTextInputProperties: FleetInputProperties[] = [
    {
      value: fleetToggleValues.cleaning_fee,
      name: 'cleaning_fee',
      label: 'Cleaning Fee',
    },
    {
      value: fleetToggleValues.daily_surcharge,
      name: 'daily_surcharge',
      label: 'Daily Surcharge',
    },
    { value: fleetToggleValues.pet_fee, name: 'pet_fee', label: 'Pet Fee' },
    {
      value: fleetToggleValues.smoking_fee,
      name: 'smoking_fee',
      label: 'Smoking Fee',
    },
  ];

  return (
    <Grid container spacing={2}>
      {fleetTextInputProperties.map(({ value, name, label }) => (
        <Grid item xs={6}>
          <FleetTextInput
            value={value}
            name={name}
            label={label}
            onChange={handleInputChange}
            isDisabled={isSelectedFleet}
            onBlur={handleOnBlur}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FleetTextInputGroup;
