import React from 'react';
import { Link } from 'react-router-dom';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import BetaTag from 'src/components/PageTitle/BetaTag';

const styles = require('src/styles/variables.scss');

interface Props {
  betaFeature?: boolean;
  title: string;
  children?: any;
  previousRoute?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  backButton: {
    backgroundColor: styles.blue200,
    '&:hover': {
      backgroundColor: styles.blue200,
    },
  },
  backIcon: {
    color: styles.primaryBlue,
    height: theme.spacing(4.5),
    position: 'relative',
    width: theme.spacing(4.5),
  },
  backLink: {
    position: 'absolute',
    left: theme.spacing(-7),
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: theme.spacing(-6),
    },
  },
  wrapper: {
    alignItems: 'flex-end',
    display: 'flex',
    marginBottom: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: (props: Props) => (props.previousRoute ? theme.spacing(5) : 0),
    },
  },
}));

const PageTitle = (props: Props) => {
  const {
    betaFeature,
    title,
    children,
    previousRoute,
  } = props;
  const classes = useStyles(props);

  return (
    <header className={classes.wrapper}>
      {previousRoute && (
        <Link to={previousRoute} className={classes.backLink}>
          <IconButton className={classes.backButton} component="span">
            <ChevronLeft className={classes.backIcon} />
          </IconButton>
        </Link>
      )}
      <Typography variant="h1">
        {title}
      </Typography>
      {betaFeature && <BetaTag />}
      {children}
    </header>
  );
};

export default PageTitle;
