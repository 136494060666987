import React from 'react';
import {
  BuildOutlined,
  Check,
  CheckCircle,
  CloudUploadOutlined,
  Error,
  Info,
  LinkOffOutlined,
  ListAlt,
  SearchOutlined,
  Warning,
} from '@material-ui/icons';

import {
  APP_MESSAGE_TYPES,
  SNACKBAR_TYPES,
} from 'src/constants';

export const getSnackbarIcon = (className: string, type: string) => {
  switch (type) {
    case SNACKBAR_TYPES.SUCCESS:
      return <CheckCircle className={className} />;
    case SNACKBAR_TYPES.ERROR:
      return <Error className={className} />;
    case SNACKBAR_TYPES.WARNING:
      return <Warning className={className} />;
    case SNACKBAR_TYPES.INFO:
      return <Info className={className} />;
    default:
      return null;
  }
};

export const getMessageIcon = (type: string, className?: string) => {
  switch (type) {
    case APP_MESSAGE_TYPES.NETWORK:
      return <BuildOutlined className={className} />;
    case APP_MESSAGE_TYPES.SEARCH:
      return <SearchOutlined className={className} />;
    case APP_MESSAGE_TYPES.PROCESSING:
      return <CloudUploadOutlined className={className} />;
    case APP_MESSAGE_TYPES.LINK_EXPIRED:
      return <LinkOffOutlined className={className} />;
    case APP_MESSAGE_TYPES.SUCCESS:
      return <Check className={className} />;
    case APP_MESSAGE_TYPES.INFORMATION:
      return <ListAlt className={className} />;
    default:
      return null;
  }
};
