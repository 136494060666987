import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { modal as modalSelector } from 'src/store/selectors/modal';
import { closeModal } from 'src/store/actions/modal';
import ConfirmModal from 'src/components/Modal/presenter';
import { MODAL_TYPES } from 'src/constants';

const ModalContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, modalType, data } = useSelector(modalSelector);

  const handleClose = useCallback(
    () => {
      dispatch(closeModal());
    },
    [dispatch],
  );

  history.listen(() => {
    handleClose();
  });

  switch (modalType) {
    case MODAL_TYPES.CONFIRM:
      return (
        <ConfirmModal
          data={data}
          isOpen={isOpen}
          onClose={handleClose}
          onCancel={handleClose}
          onConfirm={data.onConfirmButtonClick}
        />
      );
    default:
      return null;
  }
};

export default ModalContainer;
