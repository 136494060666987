import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from 'src/store/actions/modal/constants';
import { ModalState, ModalAction } from 'src/store/reducers/modal/types';

export const initialModalState: ModalState = {
  isOpen: false,
  modalType: '',
  data: {},
};

export const modalReducer = (state = initialModalState, action: ModalAction) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        ...payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
