import React, { ReactNode } from 'react';
import {
  Box,
  Container,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

import MetaTitle from 'src/components/MetaTitle';
import PageTitle from 'src/components/PageTitle';
import { Width } from 'src/store/types';

interface Props {
  children?: ReactNode;
  maxWidth?: Width;
  betaFeature?: boolean;
  title?: string;
  logo?: any;
  withFooter?: boolean;
  rightAction?: ReactNode;
  previousRoute?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: (props: Props) => ({
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${theme.spacing(9)}px)`, // page header (9)
    marginBottom: props.withFooter ? theme.spacing(18) : theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${theme.spacing(9 + 8)}px)`, // page header (9) + toolbar (8)
    },
    [theme.breakpoints.down('sm')]: {
      boxSizing: 'border-box',
      height: '100%',
    },
    [theme.breakpoints.down('xl')]: {
      boxSizing: 'border-box',
    },
  }),
  title: {
    marginBottom: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
  },
  rightActionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Layout = (props: Props) => {
  const {
    children,
    betaFeature,
    title,
    maxWidth = 'md',
    logo,
    rightAction,
    previousRoute,
  } = props;
  const classes = useStyles(props);

  return (
    <Container className={classes.container} maxWidth={maxWidth}>
      {title && (
        <PageTitle
          title={title}
          betaFeature={betaFeature}
          previousRoute={previousRoute}
        >
          <MetaTitle title={title} />
          {logo}
          {rightAction && (
            <Box className={classes.rightActionContainer}>
              {rightAction}
            </Box>
          )}
        </PageTitle>
      )}
      {children}
    </Container>
  );
};

export default Layout;
