import { GET_DEALERSHIP } from 'src/store/actions/currentDealership/constants';
import {
  CurrentDealershipData,
  CurrentDealershipState,
} from 'src/store/reducers/currentDealership/types';
import { EVENTS, STATUSES } from 'src/store/lib/constants';

type CurrentDealershipEvent = {
  data: CurrentDealershipData,
  error: string,
  type: string;
};

export const initialCurrentDealershipState: CurrentDealershipState = {
  status: STATUSES.IDLE,
  data: null,
  error: null,
};

const idleReducer = (
  state: CurrentDealershipState,
  event: CurrentDealershipEvent,
): CurrentDealershipState => {
  switch (event.type) {
    case `${GET_DEALERSHIP}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    default:
      return state;
  }
};

const loadingReducer = (
  state: CurrentDealershipState,
  event: CurrentDealershipEvent,
): CurrentDealershipState => {
  switch (event.type) {
    case `${GET_DEALERSHIP}/${EVENTS.RESOLVE}`:
      return {
        status: STATUSES.SUCCESS,
        data: event.data,
        error: null,
      };
    case `${GET_DEALERSHIP}/${EVENTS.REJECT}`:
      return {
        ...state,
        status: STATUSES.FAILURE,
        error: event.error,
      };
    default:
      return state;
  }
};

const successFailureReducer = (
  state: CurrentDealershipState,
  event: CurrentDealershipEvent,
): CurrentDealershipState => {
  switch (event.type) {
    case `${GET_DEALERSHIP}/${EVENTS.FETCH}`:
      return {
        ...state,
        status: STATUSES.LOADING,
        error: null,
      };
    case `${GET_DEALERSHIP}/${EVENTS.CLEAR}`:
      return initialCurrentDealershipState;
    default:
      return state;
  }
};

const currentDealership = (
  state: CurrentDealershipState = initialCurrentDealershipState,
  event: CurrentDealershipEvent,
): CurrentDealershipState => {
  switch (state.status) {
    case STATUSES.IDLE:
      return idleReducer(state, event);
    case STATUSES.LOADING:
      return loadingReducer(state, event);
    case STATUSES.SUCCESS:
    case STATUSES.FAILURE:
      return successFailureReducer(state, event);
    default:
      return state;
  }
};

export default currentDealership;
