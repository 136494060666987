import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  memo,
} from 'react';
import { useDispatch } from 'react-redux';
import { AgreementLanguages, SNACKBAR_TYPES } from 'src/constants';
import { openSnackbar } from 'src/store/actions/snackbar';
import SkeletonLoader from 'src/components/SkeletonLoader';
import { useAgreementsToggle } from 'src/utils/hooks/agreementToggles';
import AgreementItem from 'src/components/AgreementToggle/AgreementItem';

interface Props {
  toggleGroupId: number;
  isFormValid?: boolean;
  isCreating?: boolean;
  itemToEdit?: number;
  agreementTranslations?: AgreementLanguages;
  canAddMultipleTranslations?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onDelete?: (id: number) => void;
  onEdit?: (event: FormEvent) => void;
  setItemToEdit?: (id: number) => void;
  setAgreements?: React.Dispatch<AgreementLanguages>;
}

const AgreementList = (props: Props) => {
  const dispatch = useDispatch();
  const {
    toggleGroupId,
  } = props;

  const {
    data,
    hasError,
    isLoading,
  } = useAgreementsToggle(toggleGroupId);

  if (hasError) {
    dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, 'Something went wrong. Please refresh or try again later.'));
  }

  if (isLoading) return <SkeletonLoader />;

  return (
    <>
      {data?.map((item, index) => {
        const key = item.id.toString();
        return (
          <AgreementItem
            key={key}
            data={item}
            index={index}
          />
        );
      })}
    </>
  );
};

export default memo(AgreementList);
