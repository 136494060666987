import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { ChangeEvent, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Input/TextInput';

interface Props {
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  name?: string
  isDisabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(4),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(4),
    },
    sectionTitle: {
      paddingBottom: theme.spacing(2),
    },
    container: {
      paddingTop: theme.spacing(2),
      width: theme.spacing(28),
    },
    gridTop: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      margin: theme.spacing(0),
      width: theme.spacing(22),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
    },
    switch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  }));

const FeeTextInput = (props: Props) => {
  const classes = useStyles();
  const {
    label, value, onChange, name, isDisabled = false, onBlur,
  } = props;
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid className={classes.gridTop} item xs={10}>
        <Typography variant="h5">{label}</Typography>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={10}>
        <TextInput
          className={classes.input}
          id=""
          name={name}
          inputProps={{
            min: 0,
            max: 1000,
            step: 0.01,
          }}
          label={t('dynamicContent.amount')}
          onBlur={onBlur}
          onChange={onChange}
          required
          prefix="$"
          value={value}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default FeeTextInput;
