import React, { Fragment } from 'react';
import { Toolbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Dealership } from 'src/utils/hooks/dealerships/types';
import { APP_MESSAGE_TYPES } from 'src/constants';

import Header from 'src/components/Header';
import Layout from 'src/components/Layout';
import Table from 'src/components/Table';
import TableHeader from 'src/components/Table/TableHeader';
import LocationPagination from 'src/components/Table/Pagination';
import NoResults from 'src/components/Table/NoResults';
import AppMessage from 'src/components/AppMessage/presenter';

const columns = [
  {
    label: 'Name',
    id: 'name',
  },
];

interface Props {
  dealerships: Dealership[];
  handlePageChange: (page: number, perPage: number, directionToCache?: string) => void;
  handleRefresh: () => void;
  handleRowClick: (data: Dealership) => void;
  handleSearchChange: (text: string) => void;
  hasCurrentDealership: boolean;
  hasError: boolean;
  isLoading: boolean;
  page: number;
  perPage: number;
  searchValue: string;
  total: number;
}

const SelectLocation = (props: Props) => {
  const {
    dealerships,
    handlePageChange,
    handleRefresh,
    handleRowClick,
    handleSearchChange,
    hasCurrentDealership,
    hasError,
    isLoading,
    page,
    perPage,
    searchValue,
    total,
  } = props;
  const { t } = useTranslation();

  const NetworkErrorMessage = (
    <AppMessage
      title={t('errors.generic')}
      type={APP_MESSAGE_TYPES.NETWORK}
      disableBackground
      buttonText={t('errors.refresh')}
      onClick={handleRefresh}
    />
  );

  const HeaderComponent = (
    <TableHeader
      onSearchChange={handleSearchChange}
      searchText={t('selectLocation.searchText')}
      searchValue={searchValue}
      title={t('selectLocation.label')}
    />
  );

  const PaginationComponent = !hasError && Boolean(total) && (
    <LocationPagination
      dataTotal={total}
      onPageChange={handlePageChange}
      page={page - 1}
      perPage={perPage}
    />
  );

  return (
    <>
      {!hasCurrentDealership && (
        <>
          <Header />
          <Toolbar />
        </>
      )}
      <Layout maxWidth="md">
        <Table
          ariaLabel={t('selectLocation.label')}
          columns={columns}
          emptyTableMessage={<NoResults />}
          errorTableMessage={NetworkErrorMessage}
          HeaderComponent={HeaderComponent}
          hasError={hasError}
          hideColHeaders
          isLoading={isLoading}
          onRowClick={handleRowClick}
          PaginationComponent={PaginationComponent}
          rows={dealerships}
          title={t('selectLocation.label')}
        />
      </Layout>
    </>
  );
};

export default SelectLocation;
