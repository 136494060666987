import React, {
  ReactNode,
  Fragment,
} from 'react';
import {
  Container,
  Card,
  Typography,
  Box,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import Button from 'src/components/Button';
import { getMessageIcon } from 'src/utils/icons';
import { BOX_SHADOWS } from 'src/constants';

const styles = require('src/styles/variables.scss');

interface Props {
  bodySubText?: string;
  bodyText?: ReactNode[];
  buttonText?: string;
  disableBackground?: boolean;
  height?: number;
  isSuccessMessage?: boolean;
  onClick?: () => void;
  title?: string;
  type: string;
  width?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      alignItems: 'center',
      boxShadow: (props: Props) => (props.disableBackground ? 'none' : BOX_SHADOWS.DP5),
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(6),
    },
    messageContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      marginTop: (props: Props) => (props.disableBackground ? 0 : theme.spacing(4)),
    },
    icon: {
      color: (props: Props) => (
        props.isSuccessMessage ? styles.success50 : styles.primaryBlue
      ),
      height: (props: Props) => (
        props.height ? theme.spacing(props.height) : theme.spacing(6)
      ),
      width: (props: Props) => (
        props.width ? theme.spacing(props.width) : theme.spacing(6)
      ),
    },
    iconContainer: {
      borderRadius: '50%',
      backgroundColor: (props: Props) => (
        props.isSuccessMessage ? styles.success10 : styles.powderBlue
      ),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    message: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
    },
    button: {
      marginTop: theme.spacing(3),
    },
  }));

const AppMessage = (props: Props) => {
  const classes = useStyles(props);
  const {
    bodySubText,
    bodyText,
    buttonText,
    disableBackground,
    onClick,
    title,
    type,
  } = props;

  const button = buttonText && onClick && (
    <Button
      className={classes.button}
      label={buttonText}
      onClick={onClick}
      size="large"
      variant="contained"
    />
  );

  const messageContents = (
    <Box className={classes.messageContainer}>
      <Box className={classes.iconContainer}>
        {getMessageIcon(type, classes.icon)}
      </Box>
      {title && (
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      )}
      {bodyText && (
        <Typography className={classes.message} variant="body1">
          {bodyText?.map((text: string, index: number) => (
            <Fragment key={`bodyText-${index}`}>
              {text}
            </Fragment>
          ))}
        </Typography>
      )}
      {bodySubText && (
        <Typography className={classes.message} variant="body1">
          {bodySubText}
        </Typography>
      )}
      {button}
    </Box>
  );

  const wrapper = disableBackground
    ? <Box className={classes.cardContainer}>{messageContents}</Box>
    : <Card className={classes.cardContainer}>{messageContents}</Card>;

  return (
    <Container className={classes.container}>
      {wrapper}
    </Container>
  );
};

export default AppMessage;
