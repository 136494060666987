import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import {
  addDays,
  endOfDay,
  format,
  startOfDay,
} from 'date-fns';
import i18next from 'i18next';
import { LanguageMap } from 'src/utils/time/types';

class DateTime {
  readonly date: Date;

  constructor(date: Date = new Date()) {
    this.date = date;
  }

  addDays(n: number): DateTime {
    return new DateTime(addDays(this.date, n));
  }

  endOfDay(): DateTime {
    return new DateTime(endOfDay(this.date));
  }

  forTimeZone(timeZone: string): DateTime {
    return new DateTime(utcToZonedTime(this.date, timeZone));
  }

  getTime(): number {
    return this.date.getTime();
  }

  startOfDay(): DateTime {
    return new DateTime(startOfDay(this.date));
  }

  toISOString(): string {
    return this.date.toISOString();
  }

  formatDate(pattern: string = 'M/d/yyyy h:mm a'): string {
    return format(this.date, pattern);
  }

  formatDateLocale(): string {
    const languages: LanguageMap = {
      en: 'M/dd/yyyy',
      'en-US': 'M/dd/yyyy',
      'en-CA': 'yyyy-MM-dd',
      fr: 'yyyy-MM-dd',
      'fr-CA': 'yyyy-MM-dd',
    };
    const pattern = languages[i18next.language] || languages.en;

    return this.formatDate(pattern);
  }

  formatDateTimeLocale(hasTimeZone = false): string {
    const languages: LanguageMap = {
      en: 'M/dd/yyyy h:mm a',
      'en-US': 'M/d/yyyy h:mm a',
      'en-CA': 'yyyy-MM-dd h:mm a',
      fr: 'yyyy-MM-dd h:mm a',
      'fr-CA': 'yyyy-MM-dd HH:mm',
    };
    const pattern = languages[i18next.language] || languages.en;
    const formatter = `${pattern}${hasTimeZone ? ', OOOO' : ''}`;

    return this.formatDate(formatter);
  }

  formatDateTimeZone(timeZone: string, includeTime = false): string {
    const languages: LanguageMap = {
      en: 'M/d/yyyy',
      'en-US': 'M/d/yyyy',
      'en-CA': 'yyyy-MM-dd',
      fr: 'yyyy-MM-dd',
      'fr-CA': 'yyyy-MM-dd',
    };
    const date = utcToZonedTime(this.date, timeZone);
    const pattern = languages[i18next.language] || languages.en;
    const formatter = `${pattern}${includeTime ? ' h:mm a zzz' : ''}`;

    return tzFormat(date, formatter, { timeZone });
  }
}

export default DateTime;
