import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { authenticatedReducer as auth, initialAuthenticatedState } from 'src/store/reducers/auth';
import currentDealership, { initialCurrentDealershipState } from 'src/store/reducers/currentDealership';
import forms, { initialFormsState } from 'src/store/reducers/forms';
import { modalReducer as modal, initialModalState } from 'src/store/reducers/modal';
import snackbar, { initialSnackbarState } from 'src/store/reducers/snackbar';
import { userReducer as user, initialUserState } from 'src/store/reducers/user';

export const initialAppState = {
  auth: initialAuthenticatedState,
  forms: initialFormsState,
  modal: initialModalState,
  snackbar: initialSnackbarState,
  currentDealership: initialCurrentDealershipState,
  user: initialUserState,
};

export type AppState = typeof initialAppState & {
  router: RouterState;
};

export const rootReducer = (history: History) =>
  combineReducers({
    auth,
    forms,
    modal,
    snackbar,
    currentDealership,
    user,
    router: connectRouter(history),
  });

export default rootReducer;
