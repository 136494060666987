import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
}

const MetaTitle = (props: Props) => {
  const { title } = props;
  const { t } = useTranslation();
  const appName = t('appName.labelShort');

  const subtitle = (pageTitle: string) => (
    pageTitle ? ` - ${pageTitle}` : ''
  );

  return (
    <title>{`${appName}${subtitle(title)}`}</title>
  );
};

export default MetaTitle;
