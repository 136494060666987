import React, { ChangeEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  createStyles,
  Divider,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Button from 'src/components/Button';
import TextInput from 'src/components/Input/TextInput';
import { Contract } from './types';

interface Props {
  contract: Contract,
  loading?: boolean,
  onSaveClick: (newContract: Contract) => void;
  onSearchClick: () => void;
  onSearchTextChange: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardActions: {
      justifyContent: 'flex-end',
    },
    contractInputs: {
      flexDirection: 'row',
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    searchInput: {
      width: 200,
      marginRight: theme.spacing(1),
    },
    searchButton: {},
    textField: {
      width: '45%',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }));

const Presenter = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { contract, loading } = props;
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);

  const onSearchClick = () => {
    props?.onSearchClick();
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    props?.onSearchTextChange(event.target.value);
  };

  const onCancelClick = () => {
    pickupRef.current.value = contract.actual_pickup;
    dropoffRef.current.value = contract.actual_dropoff;
  };

  const onSaveClick = () => {
    const newContract = { ...contract };
    newContract.actual_pickup = pickupRef.current.value;
    newContract.actual_dropoff = dropoffRef.current.value;
    props?.onSaveClick(newContract);
  };

  useEffect(() => {
    if (!contract) return;

    pickupRef.current.value = contract.actual_pickup;
    dropoffRef.current.value = contract.actual_dropoff;
  }, [contract]);

  const canEdit = contract && contract.current_state === 'finished';

  return (
    <>
      <Box className={classes.searchBox}>
        <TextInput
          id="contractSearchInput"
          isDisabled={loading}
          label={t('contractsPage.searchInput')}
          onChange={onSearchTextChange}
          className={classes.searchInput}
        />
        <Button
          isLoading={loading}
          label={t('contractsPage.searchButton')}
          onClick={onSearchClick}
          variant="contained"
        />
      </Box>
      {!contract && (
        <Typography variant="h5">{t('contractsPage.description')}</Typography>
      )}
      {contract && (
        <Card className={classes.card} elevation={2}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6">{t('contractsPage.contractCard.contractId') + contract.id}</Typography>
            <Divider className={classes.divider} />
            <Box className={classes.contractInputs}>
              <TextField
                label={t('contractsPage.contractCard.confirmationToken')}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={contract.confirmation_token}
                disabled
              />
              <TextField
                label={t('contractsPage.contractCard.status')}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={contract.current_state}
                disabled
              />
              <TextField
                label={t('contractsPage.contractCard.actualPickup')}
                inputRef={pickupRef}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                type="datetime-local"
                defaultValue={contract.actual_pickup}
                InputLabelProps={{ shrink: true }}
                disabled={loading || !canEdit}
              />
              <TextField
                label={t('contractsPage.contractCard.actualDropoff')}
                inputRef={dropoffRef}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                type="datetime-local"
                defaultValue={contract.actual_dropoff}
                InputLabelProps={{ shrink: true }}
                disabled={loading || !canEdit}
              />
            </Box>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              label={t('contractsPage.contractCard.cancel')}
              variant="text"
              onClick={onCancelClick}
              isDisabled={loading}
            />
            <Button
              label={t('contractsPage.contractCard.save')}
              variant="contained"
              onClick={onSaveClick}
              isLoading={loading}
              isDisabled={loading || !canEdit}
            />
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default Presenter;
