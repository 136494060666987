import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  ({
    card: {
      marginBottom: theme.spacing(4),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(2),
    },
    sectionTitle: {
      paddingBottom: theme.spacing(2),
    },
    container: {
      paddingTop: theme.spacing(2),
      width: theme.spacing(28),
    },
    gridTop: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      margin: theme.spacing(0),
      width: theme.spacing(22),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
    },
    switch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    variablesContainer: {
      marginTop: theme.spacing(3),
    },
    settingsDivider: {
      marginBottom: theme.spacing(3),
    },
  }));
