import React, {
  MouseEvent,
  KeyboardEvent,
} from 'react';
import {
  Button,
  Typography,
  ClickAwayListener,
  Grow,
  Theme,
  makeStyles,
  Popper,
  MenuItem,
  MenuList,
  Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { Filter } from 'src/components/Table/types';
import { BOX_SHADOWS } from 'src/constants';

const styles = require('src/styles/variables.scss');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  popper: {
    boxShadow: BOX_SHADOWS.TABLE,
    zIndex: 20,
    top: theme.spacing(1),
  },
  button: (props: Filter) => ({
    minWidth: theme.spacing(12.5),
    borderRadius: theme.spacing(4.5),
    border: `1px solid ${props.active ? styles.primaryBlue : styles.gray400}`,
    height: theme.spacing(4.5),
    background: props.active ? styles.powderBlue : styles.white,
    boxShadow: 'none',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: styles.gray200,
      boxShadow: 'none',
    },
  }),
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'none',
    color: styles.gray900,
    fontSize: '13px',
    lineHeight: 1.54,
    letterSpacing: '0.25px',
  },
  filterValue: {
    fontWeight: styles.fontSemibold,
  },
  paper: {
    minWidth: theme.spacing(22),
  },
}));

const TableFilter = (props: Filter) => {
  const {
    id,
    label,
    options,
    onChange,
    value,
    analyticsDomain,
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (value: string) => (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (value) {
      onChange(id, value);
    }
    setOpen(false);
  };
  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const correspondingLabel = options?.find(option => option.value === value)?.label || value;

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        variant="contained"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{ root: classes.button, label: classes.labelContainer }}
        id={`${analyticsDomain}-filters-button`}
      >
        <Typography variant="body2" className={classes.filterLabel}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {label}: <span className={classes.filterValue}>{correspondingLabel}</span>
        </Typography>
        {open ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            timeout={100}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose(null)}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {options && options.map(option => (
                    <MenuItem
                      onClick={handleClose(option.value)}
                      key={option.value}
                      id={`${analyticsDomain}-filters-item-${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default TableFilter;
