declare const APP_VERSION: string;

export { APP_VERSION };

export const IMAGES = {
  SM_LOGO: {
    url: 'https://dw-web-assets.s3.amazonaws.com/dealerware-logomark-rgb@3x.png',
    alt: 'Dealerware',
  },
  LOGIN: {
    url: 'https://dw-web-assets.s3.amazonaws.com/login_image.png',
    alt: '',
  },
  LOGO: {
    url: 'https://dw-web-assets.s3.amazonaws.com/logo.svg',
    alt: 'Dealerware',
  },
};

export const FORM_UPLOAD = {
  ACCEPTED_FILE_FORMAT: '.pdf',
  MAX_FILE_SIZE_IN_MB: 5,
};

export const ROUTES = {
  HOME: '/',
  FORMS: {
    ROOT: '/forms',
    UPLOAD: '/forms/upload',
    MAPPING: '/forms/mapping',
  },
  LOGIN: '/login',
  CALLBACK: '/callback',
  CONTRACTS: {
    ROOT: '/contracts',
    EDIT: '/contracts/:contractId',
  },
  DEALERSHIPS: '/dealerships',
  MANAGED_TOGGLE_GROUPS: {
    ROOT: '/managed_toggle_groups',
    NEW: '/managed_toggle_groups/new',
    EDIT: '/managed_toggle_groups/:groupId',
  },
  DYNAMIC_CONTENT: '/dynamic_content',
  REINDEX_VEHICLES: '/reindex_vehicles',
};

export const BOX_SHADOWS = {
  CARD: '0 4px 12px -4px rgba(35, 35, 35, 0.08)',
  POPPER: '0 1px 5px 0 rgba(0, 0, 0, 0.17)',
  FOOTER: '0 4px 16px -4px rgba(35, 35, 35, 0.12)',
  PAPER: '0 8px 12px -8px rgba(35, 35, 35, 0.24)',
  SKELETON: '0 8px 50px -36px rgba(0, 0, 0, 0.39)',
  APP_BAR: '0 3px 6px 0 rgba(35, 35, 35, 0.06)',
  DP1: '0 4px 12px -4px rgba(35, 35, 35, 0.08)',
  DP2: '0 4px 16px -4px rgba(35, 35, 35, 0.12)',
  DP3: '0 4px 20px -4px rgba(35, 35, 35, 0.16)',
  DP4: '0 4px 24px -4px rgba(35, 35, 35, 0.2)',
  DP5: '0 8px 12px -8px rgba(35, 35, 35, 0.24)',
  LOGIN_FORM: '-40px 0 30px -30px rgba(16, 42, 62, 0.08), -15px 0 10px -10px rgba(23, 33, 41, 0.03)',
  FORM_FOOTER: '0 4px 16px -4px rgba(35, 35, 35, 0.12)',
  TABLE: '0 4px 20px 0 rgba(35, 35, 35, 0.16)',
};

export const OEMS = [
  'Acura',
  'Alfa Romeo',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Bugatti',
  'Buick',
  'Cadillac',
  'Caterham',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ferrari',
  'Fiat',
  'Ford',
  'GMC',
  'Honda',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lamborghini',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Lotus',
  'Maserati',
  'Mazda',
  'Mercedes Benz',
  'Mini',
  'Mitsubishi',
  'Nissan',
  'Porsche',
  'Ram Trucks',
  'Rolls Royce',
  'Smart',
  'Subaru',
  'Toyota',
  'Tesla',
  'Volkswagen',
  'Volvo',
];

export const US_STATES = [
  'All States',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const INPUT_IDS = {
  ADDRESS: 'line1',
  ADDRESS2: 'line2',
  CITY: 'city',
  CONFIRM_PASSWORD: 'confirmPassword',
  DEALER_CODE: 'groupSettingsDealerCode',
  DEALERSHIP_NAME: 'name',
  EMAIL: 'email',
  NEW_PASSWORD: 'newPassword',
  PASSWORD: 'password',
  PHONE: 'phoneNumber',
  SUBREGION: 'subregion',
  VEHICLE_GROUP: 'vehicleGroup',
  ZIP: 'postalCode',
};

export const FORM_PROVIDERS = [
  'PDP',
  'AutoNation',
  'Arrowhead',
  'Sedgwick',
];

export const MODAL_TYPES = {
  CONFIRM: 'Confirm',
};

export enum SNACKBAR_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export const SNACKBAR_HIDE_DURATION = 3500;

export const CLICK_AWAY = 'clickaway';

export enum NAV_ITEM_TYPES {
  CONTRACTS = 'CONTRACTS',
  DEALERSHIPS = 'DEALERSHIPS',
  HOME = 'HOME',
  MANAGED_TOGGLE_GROUPS = 'MANAGED_TOGGLE_GROUPS',
  DYNAMIC_CONTENT = 'DYNAMIC_CONTENT',
  REINDEX_VEHICLES = 'REINDEX_VEHICLES',
}

export type NavItem = {
  label: string;
  path: string;
  type: NAV_ITEM_TYPES;
};

export const NAV_ITEMS: NavItem[] = [
  {
    label: 'navItems.home',
    path: ROUTES.HOME,
    type: NAV_ITEM_TYPES.HOME,
  },
  {
    label: 'navItems.contracts',
    path: ROUTES.CONTRACTS.ROOT,
    type: NAV_ITEM_TYPES.CONTRACTS,
  },
  {
    label: 'navItems.dynamic_content',
    path: ROUTES.DYNAMIC_CONTENT,
    type: NAV_ITEM_TYPES.DYNAMIC_CONTENT,
  },
  {
    label: 'navItems.managed_toggle_groups',
    path: ROUTES.MANAGED_TOGGLE_GROUPS.ROOT,
    type: NAV_ITEM_TYPES.MANAGED_TOGGLE_GROUPS,
  },
  {
    label: 'navItems.reindex_vehicles',
    path: ROUTES.REINDEX_VEHICLES,
    type: NAV_ITEM_TYPES.REINDEX_VEHICLES,
  },
];

export enum ACCOUNT_MENU_TYPES {
  ACCOUNT = 'ACCOUNT',
  DEALERSHIPS = 'DEALERSHIPS',
  LOG_OUT = 'LOG_OUT',
}

export interface AccountMenuItem {
  id?: string;
  label: string;
  path: string;
  type: ACCOUNT_MENU_TYPES;
}

export interface AccountMenu {
  label: string;
  path: string;
  type: ACCOUNT_MENU_TYPES;
  menuItems: AccountMenuItem[];
}

export const ACCOUNT_MENU: AccountMenu = {
  label: 'navItems.account.root',
  path: '',
  type: ACCOUNT_MENU_TYPES.ACCOUNT,
  menuItems: [
    {
      id: 'account-menu-item-location',
      label: 'navItems.dealerships',
      path: ROUTES.DEALERSHIPS,
      type: ACCOUNT_MENU_TYPES.DEALERSHIPS,
    },
    {
      id: 'account-menu-item-logout',
      label: 'navItems.logout',
      path: '',
      type: ACCOUNT_MENU_TYPES.LOG_OUT,
    },
  ],
};

export type AgreementLanguages = {
  [en: string]: string;
  fr?: string;
};

export const AGREEMENT_AVAILABLE_LANGUAGES: AgreementLanguages = {
  en: 'English',
  fr: 'French',
};

export const MAX_AGREEMENT_LENGTH = 500;

export const DUPLICATE_WARNING = 'There is already a group with the same name, consider change the name.';

export const MANAGED_TOGGLE_TEXT_1 = 'A Managed Toggle will appear on the Agreement Summary as a legal Agreement Statement. Create each Managed Toggle based on dealership, dealer group, or OEM specifications.';

export const MANAGED_TOGGLE_TEXT_2 = 'To display a rate next to an Agreement Statement, paste an Agreement Summary Fee and value into the associated Managed Toggle.';

export const TOGGLE_GROUP_INFO_1 = 'The Agreement Summary is displayed to the customer at the start of a contract. The customer must agree to each Managed Toggle on the Agreement Summary in order to begin the contract. You may add, edit, and delete Managed Toggles from the Agreement Summary below.';

export const TOGGLE_GROUP_INFO_2 = 'Some dealerships are in locations that require specific Agreement Summaries. Please determine if a specific Agreement Summary should be assigned to a dealership before making your selection.';

export const TOGGLE_GROUP_INFO_3 = 'Based upon location, a dealership may prefer that the Agreement Summary is available in English and French. Please note that if adding both English and French language to the Agreement Summary, additions or edits should be consistent throughout both languages to ensure contract validity.';

export enum APP_MESSAGE_TYPES {
  NETWORK = 'network',
  SEARCH = 'search',
  PROCESSING = 'processing',
  LINK_EXPIRED = 'link_expired',
  SUCCESS = 'success',
  INFORMATION = 'information',
}

export const LOCAL_STORAGE = {
  ANGULAR_DEALERSHIP_ID: 'angularDealershipId',
  CURRENT_DEALERSHIP: 'currentDealership',
  CURRENT_FLEET: 'currentFleet',
};

export const drawerBreakpoint = 'lg';
export const drawerWidth = '256px';
