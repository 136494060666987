import React, { FocusEvent, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Layout, TextInput } from 'dw-react-recipes';
import {
  ROUTES,
  SNACKBAR_TYPES,
  DUPLICATE_WARNING,
} from 'src/constants';
import { managedToggleGroups } from 'src/store/lib/endpoints';
import Api from 'src/utils/api';
import SkeletonLoader from 'src/components/SkeletonLoader';
import Button from 'src/components/Button';
import { openSnackbar } from 'src/store/actions/snackbar';
import Card from 'src/pages/ManagedToggleGroups/Card';
import { useManageToggleGroups } from 'src/utils/hooks/manageToggleGroups';
import * as styles from 'src/styles/variables.scss';

const handleEvent = (handler: (value: string) => void) =>
  (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    handler(e.target.value);
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(4),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
    },
    textField: {
      width: '48ch',
    },
    errorIcon: {
      color: styles.error50,
    },
    button: {
      textTransform: 'uppercase',
      marginLeft: theme.spacing(2),
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));

const CreateToggleGroup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [hasDuplicate, setHasDuplicate] = useState(false);
  const [duplicateWarning, setDuplicateWarning] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const {
    data: groups,
    hasError,
    isLoading,
    mutate,
  } = useManageToggleGroups();

  if (hasError) {
    dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, t('errors.generic')));
  }

  const checkForDuplicate = (newName: string) => {
    const lowercaseNew = newName.toLowerCase().trim();
    return groups.some(group =>
      group.name?.toLowerCase() === lowercaseNew);
  };

  const onNameChange = (newName: string) => {
    if (checkForDuplicate(newName)) {
      setDuplicateWarning(DUPLICATE_WARNING);
      setHasDuplicate(true);
    } else {
      setDuplicateWarning('');
      setHasDuplicate(false);
    }
    setName(newName);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const goToEditGroup = (locationPath: string) => {
    const groupId = locationPath.split('/').pop();
    let path = ROUTES.MANAGED_TOGGLE_GROUPS.EDIT;
    path = path.replace(':groupId', groupId);
    history.push(path);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const response = await Api.post(managedToggleGroups, { name });
      goToEditGroup(response.headers.location);
    } catch (error) {
      dispatch(openSnackbar(SNACKBAR_TYPES.ERROR, error));
    } finally {
      setIsSaving(false);
      mutate();
    }
  };

  const ErrorIcon = (
    <ErrorOutline className={classes.errorIcon} />
  );

  const isSaveDisabled = name.trim().length === 0 || hasDuplicate || isSaving;

  return (
    <Layout
      maxWidth="md"
      title={t('managedToggleGroups.createToggleGroup.title')}
    >
      {isLoading ? <SkeletonLoader /> : (
        <>
          <Typography variant="body2">{t('managedToggleGroups.cardBody')}</Typography>
          <Card
            className={classes.card}
            title={t('managedToggleGroups.createToggleGroup.cardTitle')}
          >
            <Box className={classes.box}>
              <TextInput
                required
                className={classes.textField}
                label={t('managedToggleGroups.createToggleGroup.textInputLabel')}
                onBlur={handleEvent(onNameChange)}
                onChange={handleEvent(onNameChange)}
                value={name}
                helperText={duplicateWarning}
                suffix={hasDuplicate ? ErrorIcon : null}
              />
              <Box>
                <Button
                  className={classes.button}
                  isDisabled={isSaving}
                  isLoading={false}
                  label={t('managedToggleGroups.buttons.cancel')}
                  type="submit"
                  onClick={handleCancel}
                  variant="text"
                />
                <Button
                  className={classes.button}
                  isDisabled={isSaveDisabled}
                  isLoading={false}
                  label={t('managedToggleGroups.buttons.save')}
                  type="submit"
                  onClick={handleSave}
                  variant="contained"
                />
              </Box>
            </Box>
          </Card>
        </>
      )}
    </Layout>
  );
};

export default CreateToggleGroup;
