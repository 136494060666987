import useSWR from 'swr';

import { dealerships, dealershipSearch } from 'src/store/lib/endpoints';
import fetcher from 'src/utils/api/fetcher';
import { defaultOptions } from 'src/utils/hooks/helpers';
import { Payload } from 'src/utils/hooks/dealerships/types';

const useDealerships = (options = defaultOptions): Payload => {
  const {
    page, perPage, preventFetch, search,
  } = options;
  const url = search
    ? dealershipSearch(search, page, perPage)
    : dealerships(page, perPage);
  const key = preventFetch ? null : url;
  const { data: response, error, mutate } = useSWR(key, fetcher, { revalidateOnFocus: false });
  const data = response ? response.data : null;
  const pagination = response ? response.pagination : null;

  return {
    data,
    isLoading: !error && !response,
    hasError: Boolean(error),
    mutate,
    pagination,
  };
};

export default useDealerships;
