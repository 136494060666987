import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => createStyles({
  noMatch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  noMatchIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  noMatchIconWrapper: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const NoResults = () => {
  const classes = useStyles({});
  const { t } = useTranslation();

  return (
    <Box className={classes.noMatch}>
      <Box className={classes.noMatchIconWrapper}>
        <Search className={classes.noMatchIcon} color="primary" />
      </Box>
      <Typography variant="h2">{t('vehicles.table.emptyMessage')}</Typography>
    </Box>
  );
};

export default NoResults;
