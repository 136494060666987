import React, {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  InputAdornment,
  Typography,
  Divider,
  Theme,
  createStyles,
  makeStyles,
  Grid,
  InputBase,
} from '@material-ui/core';
import { ArrowBack, Clear, Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import IconButton from 'src/components/Input/IconButton';
import Button from 'src/components/Button';
import TableFilter from 'src/components/Table/TableFilter';
import { Filter } from 'src/components/Table/types';
import useDebounce from 'src/utils/hooks/debounce';
import { AnalyticsDomains } from 'src/constants/analytics';

const styles = require('src/styles/variables.scss');

interface Props {
  analyticsDomain?: AnalyticsDomains;
  title?: string;
  onNavigationClick?: (e: MouseEvent) => void;
  showNavigationIcon?: boolean;
  searchText?: string;
  onSearchChange?: (text: string) => void;
  onSearchBlur?: (text: string) => void;
  searchValue?: string;
  secondaryActionIcon?: ReactNode;
  secondaryActionMenu?: ReactNode;
  secondaryActionAriaLabel?: string;
  onSecondaryActionClick?: (e: MouseEvent) => void;
  bulkActionText?: string;
  onBulkActionSelect?: () => void;
  numberSelected?: number;
  filters?: Filter[];
}

interface StyleProps {
  searchBuffer: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    actionsWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      height: theme.spacing(4.5),
    },
    search: {
      display: 'flex',
    },
    filterWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    iconButton: {
      pointerEvents: (props: StyleProps) => (props.searchBuffer ? 'auto' : 'none'),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    searchIcon: {
      color: styles.gray700,
    },
    searchInput: {
      border: `1px solid ${styles.gray400}`,
      borderRadius: 25,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      width: theme.spacing(30),
      '&:focus-within': {
        backgroundColor: styles.gray200,
      },
    },
    secondaryAction: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
    },
    bulkAction: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

const TableHeader = (props: Props) => {
  const {
    title,
    onNavigationClick,
    showNavigationIcon,
    searchText,
    searchValue,
    onSearchChange,
    onSearchBlur,
    secondaryActionIcon,
    secondaryActionMenu,
    secondaryActionAriaLabel,
    onSecondaryActionClick,
    numberSelected,
    bulkActionText,
    onBulkActionSelect,
    filters,
    analyticsDomain,
  } = props;
  const [searchBuffer, setSearchBuffer] = useState(searchValue || '');
  const debouncedSearchValue = useDebounce(searchBuffer, 500);
  const classes = useStyles({ searchBuffer });
  const { t } = useTranslation();
  const hasSearchBuffer = Boolean(searchBuffer?.length);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchValue);
    }
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  useEffect(() => {
    setSearchBuffer(searchValue || '');
  }, [searchValue]);

  const clearSearch = () => {
    setSearchBuffer('');
    if (onSearchChange) {
      onSearchChange('');
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchBuffer(e.target.value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!onSearchBlur) return;
    onSearchBlur(e.target.value);
  };

  const navigationIcon = showNavigationIcon && (
    <IconButton
      ariaLabel="go back"
      icon={<ArrowBack />}
      onClick={onNavigationClick}
      size="medium"
    />
  );

  const searchIcon = (
    <InputAdornment
      position="end"
      disableTypography
    >
      <IconButton
        ariaLabel={t(`table.search.${hasSearchBuffer ? 'clear' : 'search'}`)}
        className={classes.iconButton}
        icon={hasSearchBuffer ? <Clear /> : <Search className={classes.searchIcon} />}
        onClick={hasSearchBuffer ? clearSearch : null}
        size="medium"
      />
    </InputAdornment>
  );

  const search = onSearchChange && (
    <div className={classes.search}>
      <InputBase
        className={classes.searchInput}
        endAdornment={searchIcon}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={searchText}
        id={`${analyticsDomain}-search-input`}
        value={searchBuffer}
      />
    </div>
  );

  const secondaryAction = secondaryActionIcon && (
    <div className={classes.secondaryAction}>
      <IconButton
        ariaLabel={secondaryActionAriaLabel}
        icon={secondaryActionIcon}
        onClick={onSecondaryActionClick}
        size="medium"
        id={`${analyticsDomain}-secondary-action`}
      />
      {secondaryActionMenu}
    </div>
  );

  const filtersContainer = filters && filters.length && (
    <div className={classes.filterWrapper}>
      {filters.map(filter => <TableFilter key={filter.id} {...filter} />)}
    </div>
  );

  const titleContent = numberSelected ? `${numberSelected} selected` : title;

  const bulkActionButton = onBulkActionSelect && (
    <Box className={classes.bulkAction}>
      <Button label={bulkActionText} onClick={onBulkActionSelect} variant="text" />
    </Box>
  );

  return (
    <Box>
      <Grid container className={classes.container}>
        <Grid item xs={3} className={classes.title}>
          { !numberSelected && navigationIcon }
          <Typography variant="h3">{titleContent}</Typography>
        </Grid>
        <Grid item xs={9} className={classes.actionsWrapper}>
          { filtersContainer }
          { !numberSelected ? search : bulkActionButton }
          { !numberSelected && secondaryAction }
        </Grid>
      </Grid>
      <Divider component="hr" />
    </Box>
  );
};

export default TableHeader;
