import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles';
import { dwTheme as recipesTheme } from 'dw-react-recipes';

import store, { history } from 'src/store';
import { ErrorBoundary } from 'src/utils/bugsnag';
import App from 'src/App';
import FallbackComponent from 'src/components/ErrorBoundary/Fallback';
import 'src/translations/i18n';
import 'src/styles/App.scss';

declare let module: any;

if (module.hot) {
  module.hot.accept();
}

const generateClassName = createGenerateClassName({ productionPrefix: 'dw' });

const Wrapper = () => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={recipesTheme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </ErrorBoundary>
);

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Wrapper />,
    document.getElementById('app'),
  );
});
