import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';
import React, {
  ChangeEvent, FocusEvent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'src/store/actions/snackbar';
import { SNACKBAR_TYPES } from 'src/constants';
import Api from 'src/utils/api';
import { isValidNumberInput, formatNumber } from 'src/utils/helpers';
import { fleetToggleVariables } from 'src/store/lib/endpoints';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import SelectInput from 'src/components/Input/SelectInput';
import { Props, FleetToggles, FleetTogglesKeys } from './types';
import { useStyles } from './styles';
import FleetTextInputGroup from './FleetTextInputGroup';

const Presenter = (props: Props) => {
  const { fleets, dealershipName } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fleetToggleValues, setFleetToggleValues] = useState<FleetToggles>({
    cleaning_fee: '0.00',
    daily_surcharge: '0.00',
    pet_fee: '0.00',
    smoking_fee: '0.00',
  });
  const [selectedFleet, setSelectedFleet] = useState('');
  const isSelectedFleet = !selectedFleet;
  const fleetsOptions = fleets?.map((fleet) => ({ label: fleet.name, value: fleet.id }));

  useEffect(() => {
    if (!selectedFleet) return;

    const getFleetToggleVariables = async () => {
      const url = fleetToggleVariables(selectedFleet);

      try {
        const data = await Api.get(url);
        const newData: Partial<FleetToggles> = {};

        Object.keys(data).forEach((key: FleetTogglesKeys) => {
          newData[key] = data[key].value || '0.00';
        });

        setFleetToggleValues(newData as FleetToggles);
      } catch (error) {
        dispatch(
          openSnackbar(SNACKBAR_TYPES.ERROR, t('dynamicContent.errorOnLoadingToggles')),
        );
      }
    };

    getFleetToggleVariables();
  }, [selectedFleet, dispatch, t]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target;

    if (!isValidNumberInput(value)) return;

    setFleetToggleValues({ ...fleetToggleValues, [name]: value });
  };

  const handleFleetChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target;
    setSelectedFleet(value);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;

    if (!value) {
      setFleetToggleValues({ ...fleetToggleValues, [name]: '0.00' });
    } else {
      setFleetToggleValues({
        ...fleetToggleValues,
        [name]: formatNumber(Number(value)),
      });
    }
  };

  const handleOnSave = async () => {
    try {
      await Api.put(fleetToggleVariables(selectedFleet), fleetToggleValues);
      dispatch(
        openSnackbar(SNACKBAR_TYPES.SUCCESS, t('dynamicContent.successMessage')),
      );
    } catch (error) {
      dispatch(
        openSnackbar(SNACKBAR_TYPES.ERROR, t('dynamicContent.errorMessage')),
      );
    }
  };

  return (
    <>
      <Card
        className={classes.card}
        title={t('dynamicContent.dealerships')}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid className={classes.gridTop} item xs={7}>
              <Typography variant="h2">{dealershipName}</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectInput
                label="Select a Fleet"
                options={fleetsOptions}
                value={selectedFleet}
                onChange={handleFleetChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.variablesContainer}>
          <Grid container spacing={2}>
            <Grid className={classes.gridTop} item xs={7}>
              <Typography variant="h6">{t('dynamicContent.settingsTitle')}</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.settingsDivider} />

          <FleetTextInputGroup
            handleInputChange={handleInputChange}
            fleetToggleValues={fleetToggleValues}
            handleOnBlur={handleOnBlur}
            isSelectedFleet={isSelectedFleet}
          />
        </Box>

        <Box className={classes.footer}>
          <Button
            id="settings-vehicle-groups-done-button"
            onClick={handleOnSave}
            label={t('dynamicContent.button')}
            variant="contained"
            isDisabled={isSelectedFleet}
          />
        </Box>
      </Card>
    </>
  );
};

export default Presenter;
