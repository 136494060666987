import Auth0Lock from 'auth0-lock';
import { IMAGES } from 'src/constants';

export const AuthLock = new Auth0Lock(
  process.env.AUTH_CLIENT_ID,
  process.env.AUTH_DOMAIN,
);

export const Auth0LockOptions = {
  allowedConnections: ['Username-Password-Authentication'],
  languageDictionary: {
    emailInputPlaceholder: 'Email',
    title: 'Admin Portal',
    passwordInputPlaceholder: 'Password',
  },
  allowSignUp: false,
  autoclose: true,
  allowAutocomplete: true,
  allowPasswordAutocomplete: true,
  allowShowPassword: true,
  auth: {
    audience: process.env.AUTH_AUDIENCE,
    params: {
      responseType: process.env.AUTH_RESPONSE_TYPE,
      scope: process.env.AUTH_SCOPE,
      protocol: process.env.AUTH_PROTOCOL,
    },
  },
  rememberLastLogin: false,
  theme: {
    logo: IMAGES.LOGO.url,
    primaryColor: '#0D6CF2',
  },
};
