import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Layout } from 'dw-react-recipes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      marginBottom: theme.spacing(2),
    },
    h3: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  }));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Layout title={t('homePage.title')} maxWidth="md">
      <Box>
        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.desc')}</Typography>
        <Typography variant="h2" className={classes.h3}>{t('homePage.toggleGroupsTitle')}</Typography>

        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.toggleGroupsP1')}</Typography>
        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.toggleGroupsP2')}</Typography>
        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.toggleGroupsP3')}</Typography>

        <Typography variant="h2" className={classes.h3}>{t('homePage.dealershipsTitle')}</Typography>

        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.dealershipsP1')}</Typography>
        <Typography variant="body1" classes={{ root: classes.body }}>{t('homePage.dealershipsP2')}</Typography>
      </Box>

    </Layout>
  );
};

export default Home;
